// NOTA: 11 Rutas
import AccesoriosIndex from './accesoriosIndex';
import SamsungPaginaComponente from './accesoriosSamsung';
import WhirpoolPaginaComponente from './accesoriosWhirpool';
import PanasonicPaginaComponente from './accesoriosPanasonic';
import SonyPaginaComponente from './accesoriosSony';
import OtrosPaginaComponente from './accesoriosOtros';
import PPPaginaComponente from './accesoriosPp';
import PromocionesPaginaComponente from './accesoriosPromociones';
import NosotrosPaginaComponente from './accesoriosNosotros';
import NoticiasPaginaComponente from './accesoriosNoticias';
import ContactoPaginaComponente from './accesoriosContacto';
// Iconos
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';


const rutasAccesorios = [
  {
    path: '/accesorios',
    sidebarName: 'Accesorios',
    navbarName: 'Accesorios',
    icon: HomeIcon,
    component: AccesoriosIndex
  },
  {
    path: '/accesorios/samsung',
    sidebarName: 'Samsung',
    navbarName: 'Samsung',
    icon: MailIcon,
    component: SamsungPaginaComponente
  },
  {
    path: '/accesorios/whirpool',
    sidebarName: 'Whirpool',
    navbarName: 'Whirpool',
    icon: MailIcon,
    component: WhirpoolPaginaComponente
  },
  {
    path: '/accesorios/panasonic',
    sidebarName: 'Panasonic',
    navbarName: 'Panasonic',
    icon: MailIcon,
    component: PanasonicPaginaComponente
  },
  {
    path: '/accesorios/sony',
    sidebarName: 'Sony',
    navbarName: 'Sony',
    icon: MailIcon,
    component: SonyPaginaComponente
  },
  {
    path: '/accesorios/otros',
    sidebarName: 'Otras Marcas',
    navbarName: 'Otras Marcas',
    icon: MailIcon,
    component: OtrosPaginaComponente
  },
  {
    path: '/pp',
    sidebarName: 'Protection Pro',
    navbarName: 'Protection Pro',
    icon: InboxIcon,
    component: PPPaginaComponente
  },
  {
    path: '/promociones',
    sidebarName: 'Samsung',
    navbarName: 'Samsung',
    icon: InboxIcon,
    component: PromocionesPaginaComponente
  },
  {
    path: '/nosotros',
    sidebarName: 'Samsung',
    navbarName: 'Samsung',
    icon: MailIcon,
    component: NosotrosPaginaComponente
  },
  {
    path: '/noticias',
    sidebarName: 'Samsung',
    navbarName: 'Samsung',
    icon: InboxIcon,
    component: NoticiasPaginaComponente

  },
  {
    path: '/contacto',
    sidebarName: 'Samsung',
    navbarName: 'Samsung',
    icon: InboxIcon,
    component: ContactoPaginaComponente
  }
];

export default rutasAccesorios;
