import React from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.drawer + 1
  },
  aireCargador: {
    paddingBottom: theme.spacing(4)
  }
}));

const Cargador = ({ estaCargando }) => {
  const classes = useStyles();
  return (
    <Container>
      <Backdrop className={classes.backdrop} open={estaCargando}>
        <Typography
          className={classes.aireCargador}
          color='textSecondary'
          component='h5'
          variant='overline'
        >
          Espera un poco
        </Typography>
        <CircularProgress color='inherit' size={75} />
      </Backdrop>
    </Container>
  );
};

Cargador.defaultProps = {
  estaCargando: true
};
Cargador.propTypes = {
  estaCargando: PropTypes.bool
};

export default Cargador;

