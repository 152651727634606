import { combineReducers } from 'redux';
import { firebaseReducer as firebase } from 'react-redux-firebase';
import { persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import inventarioReductor from './inventario.Reductor';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { connectRouter } from 'connected-react-router';
import temaReductor from './tema.Reductor';
import idiomaReductor from './idioma.Reductor';
import dialogoReductor from './dialogo.Reductor';
import buscadorReductor from './buscador.Reductor';
import courierReductor from './courier.Reductor';

export default function makeRootReducer(history) {
  return combineReducers({
    router: connectRouter(history),
    inventario: inventarioReductor,
    toastr: toastrReducer,
    tema: temaReductor,
    lenguaje: idiomaReductor,
    dialogo: dialogoReductor,
    buscador: buscadorReductor,
    courier: courierReductor,
    firebase: persistReducer(
      { key: 'firebaseState', storage: localStorage, stateReconciler: hardSet },
      firebase
    )
  });
}
