import {
  CAT_PRODUCTOS_SOLICITA,
  CAT_PRODUCTOS_RECIBE,
  CAT_PRODUCTO_AGREGA,
  CAT_PRODUCTOS_ERROR,
  CAMBIA_CAMPO_BUSQUEDA,
  CAT_PRODUCTOS_RECIBE_BUSQUEDA,
  RESETEAR_PRODUCTOS,
  CAT_PRODUCTOS_RECIBE_LISTA
} from '../acciones/constantes';

const initialState = {
  catProductos: [],
  productoCargando: false,
  catProductoFiltrado: [],
  campoBusqueda: '',
  catProductosLista: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAMBIA_CAMPO_BUSQUEDA:
      return {
        ...state,
        campoBusqueda: action.payload
      };
    case CAT_PRODUCTOS_SOLICITA:
      return {
        ...state,
        productoCargando: true
      };
    case CAT_PRODUCTOS_RECIBE:
      return {
        ...state,
        productoCargando: false,
        catProductos: [...action.payload],
        catProductoFiltrado: []
      };
    case CAT_PRODUCTOS_RECIBE_LISTA:
      return {
        ...state,
        productoCargando: false,
        catProductosLista: [...action.payload]
      };
    case CAT_PRODUCTO_AGREGA:
      return {
        ...state,
        catProductoFiltrado: [action.payload],
        productoCargando: false
      };
    case CAT_PRODUCTOS_ERROR:
      return {
        ...state,
        productoCargando: false,
        catProductoFiltrado: []
      };
    case CAT_PRODUCTOS_RECIBE_BUSQUEDA:
      return {
        ...state,
        productoCargando: false,
        catProductoFiltrado: [action.payload]
      };
    case RESETEAR_PRODUCTOS:
      return {}; 
    default:
      return state;
  }
};

// ///
/*
import * as actions from './posts.actions';

const initialState = {isFetching: false, posts: [], errorMessage: '', skip: 0, limit: 12, hasMore: true};

export default function posts(state = initialState, action) {
  switch (action.type) {
    case actions.POSTS_REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        skip: action.skip,
        limit: action.limit,
        hasMore: true
      });
    case actions.POSTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        posts: state.posts.concat(action.posts),
        hasMore: action.hasMore
      });
    case actions.POSTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        errorMessage: action.errorMessage,
        hasMore: false
      });
    default:
      return state
  }
} */
