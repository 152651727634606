import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import ScoreIcon from '@material-ui/icons/Score';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MailIcon from '@material-ui/icons/Mail';
import BarChartIcon from '@material-ui/icons/BarChart';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import AirplayIcon from '@material-ui/icons/Airplay';
import LoopIcon from '@material-ui/icons/Loop';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import CallMadeIcon from '@material-ui/icons/CallMade';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import logo from '../../recursos/logo.png';
import { Zoom } from 'react-reveal';
import SearchIcon from '@material-ui/icons/Search';
import MemoryIcon from '@material-ui/icons/Memory';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import Avatar from '../avatar';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Collapse from '@material-ui/core/Collapse';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Resetear from '../../contenedores/Rutas/reset';
import rutasPanelControl from './rutasPanelControl.js';
import { MemoryRouter } from 'react-router';
import HomeIcon from '@material-ui/icons/Home';

const breadcrumbNameMap = {
  '/estadisticas': 'Estadisticas',
  '/hhp': 'HHP',
  '/ce': 'CE',
  '/sap': 'SAP',
  '/recolecciones': 'Recolecciones',
  '/transportistas': 'Transportistas',
  '/ingresos': 'Ingreso Guias',
  '/salidas': 'Salida Guias',
  '/tablero': 'Tablero'
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0
    }
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    minHeight: '56px'
  },
  drawerPaper: {
    width: drawerWidth
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.default
  },
  logo: {
    display: 'block',
    padding: '8px',
    width: '56px'
  },
  logo2: {
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    },
    padding: '8px',
    width: '56px'
  },
  search: {
    borderRadius: '25px',
    position: 'relative',
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    [theme.breakpoints.up('sm')]: {
      borderRadius: '25px',
      marginLeft: theme.spacing(1)
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    display: 'flex'
  },
  inputInput: {
    /* textTransform: 'uppercase', */
    letterSpacing: '0.05em',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    '&:hover': {
      cursor: 'progress'
    }
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  grow: {
    flexGrow: 1
  },
  icon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20
  },
  link: {
    display: 'flex',
    alignItems: 'center'
  }
}));

const ListItemLink = ({ to, abrir, children, ...other }) => {
  const [path, setPath] = React.useState('');
  const primary = breadcrumbNameMap[to];
  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
    console.log(location.pathname);
  }, [location, setPath]);

  const activetRoute = (route) => {
    return route === path;
  };

  return (
    <ListItem button component={RouterLink} selected={activetRoute(to)} to={to} {...other}>
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={primary} />
      {abrir != null ? abrir ? <ExpandLess /> : <ExpandMore /> : null}
    </ListItem>
  );
};

ListItemLink.propTypes = {
  abrir: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

LinkRouter.propTypes = {
  to: PropTypes.string.isRequired
};

const PaneldeControlComponente = () => {
  let { path } = useRouteMatch();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [expandir, setExpandir] = React.useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleExpandir = () => {
    setExpandir((prevOpen) => !prevOpen);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      onClose={handleMenuClose}
      open={isMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      onClose={handleMobileMenuClose}
      open={isMobileMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem>
        <IconButton aria-label='show 17 new notifications' color='inherit'>
          <Badge badgeContent={17} color='secondary'>
            <AddShoppingCartIcon />
          </Badge>
        </IconButton>
        <p>Carrito</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={4} color='secondary'>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Mensajes</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label='show 11 new notifications' color='inherit'>
          <Badge badgeContent={11} color='secondary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificaciones</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          aria-label='account of current user'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Mi perfil</p>
      </MenuItem>
    </Menu>
  );

  const drawer = (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
        <img alt='IDF' className={classes.logo2} onClick={() => dispatch(push('/'))} src={logo} />
        <IconButton onClick={handleDrawerToggle}>
          {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
        </IconButton>
      </div>
      <Divider />
      <List>
        <ListItemLink to='/estadisticas'>
          <BarChartIcon color='secondary'/>
        </ListItemLink>
        <Divider />
        <ListItemLink to='/hhp'>
          <PhonelinkRingIcon />
        </ListItemLink>
        <Divider />
        <ListItemLink to='/ce'>
          <AirplayIcon />
        </ListItemLink>
        <Divider />
        <ListItemLink to='/sap'>
          <MemoryIcon />
        </ListItemLink>
        <Divider />
        <ListItemLink to='/recolecciones'>
          <LoopIcon />
        </ListItemLink>
        <Divider />
        <ListItemLink to='/transportistas'>
          <LocalShippingIcon />
        </ListItemLink>
        <Divider />
        <ListItemLink to='/ingresos'>
          <CallReceivedIcon />
        </ListItemLink>
        <Divider />
        <ListItemLink to='/salidas'>
          <CallMadeIcon />
        </ListItemLink>
        <Divider />


{/*         <ListItemLink abrir={expandir} onClick={handleExpandir} to='/transportistas'>
          <LocalShippingIcon />
        </ListItemLink>
        <Collapse component='li' in={expandir} timeout='auto' unmountOnExit>
          <List disablePadding>
            <ListItemLink className={classes.nested} to='/transportistas/ingresos'>
              <CallReceivedIcon />
            </ListItemLink>
            <ListItemLink className={classes.nested} to='/transportistas/salidas'>
              <CallMadeIcon />
            </ListItemLink>
          </List>
        </Collapse> */}



        <Divider />
        <ListItemLink to='/tablero'>
          <ScoreIcon />
        </ListItemLink>
        <Divider />
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar className={classes.appBar} position='fixed'>
        <Toolbar style={{ minHeight: '56px' }}>
          <div>
            <Hidden implementation='css' smUp>
              <Zoom>
                <img alt='IDF' className={classes.logo} onClick={handleDrawerToggle} src={logo} />
              </Zoom>
            </Hidden>
            <Hidden implementation='css' xsDown>
              <Zoom>
                <img
                  alt='IDF'
                  className={classes.logo}
                  onClick={() => dispatch(push('/'))}
                  src={logo}
                />
              </Zoom>
            </Hidden>
          </div>
          <div className={classes.grow} />
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              inputProps={{ 'aria-label': 'buscar' }}
              placeholder='Número de parte'
              type='text'
            />
          </div>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <IconButton aria-label='show 17 new notifications' color='inherit'>
              <Badge badgeContent={17} color='secondary'>
                <AddShoppingCartIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label='show 4 new mails' color='inherit'>
              <Badge badgeContent={4} color='secondary'>
                <MailIcon />
              </Badge>
            </IconButton>
            <IconButton aria-label='show 17 new notifications' color='inherit'>
              <Badge badgeContent={17} color='secondary'>
                <NotificationsIcon />
              </Badge>
            </IconButton>

            <IconButton
              aria-controls={menuId}
              aria-haspopup='true'
              aria-label='account of current user'
              color='inherit'
              edge='end'
              style={{ height: '56px', minHeight: '56px' }}
            >
              <Avatar />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              aria-label='show more'
              color='inherit'
              onClick={handleMobileMenuOpen}
            >
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Router>
        <MemoryRouter initialEntries={['/estadisticas']} initialIndex={0}>
          <nav aria-label='mailbox folders' className={classes.drawer}>
            <Hidden implementation='css' smUp>
              <Drawer
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                classes={{
                  paper: classes.drawerPaper
                }}
                ModalProps={{
                  keepMounted: true // Better open performance on mobile.
                }}
                onClose={handleDrawerToggle}
                open={mobileOpen}
                variant='temporary'
              >
                {drawer}
              </Drawer>
            </Hidden>
            <Hidden implementation='css' smDown>
              <Drawer
                classes={{
                  paper: classes.drawerPaper
                }}
                open
                variant='permanent'
              >
                {drawer}
              </Drawer>
            </Hidden>
          </nav>
          <Container className={classes.content} maxWidth='xl'>
            <Toolbar style={{ minHeight: '56px' }}/>
            <Grid item xs={12}>
              <Route>
                {({ location }) => {
                  const pathnames = location.pathname.split('/').filter((x) => x);

                  return (
                    <Breadcrumbs aria-label='breadcrumb'>
                      <LinkRouter className={classes.link} color='primary' onClick={() => dispatch(push('/'))}>
                        <HomeIcon className={classes.icon} />
                        Bodega
                      </LinkRouter>
                      {pathnames.map((value, index) => {
                        const last = index === pathnames.length - 1;
                        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

                        return last ? (
                          <Typography color='textPrimary' key={to}>
                            {breadcrumbNameMap[to]}
                          </Typography>
                        ) : (
                          <LinkRouter color='secondary' key={to} to={to}>
                            {breadcrumbNameMap[to]}
                          </LinkRouter>
                        );
                      })}
                    </Breadcrumbs>
                  );
                }}
              </Route>
              <Divider style={{ marginBottom: '15px' }}/>
            </Grid>

            <Resetear>
              <Switch>
                {rutasPanelControl.map((ruta) => (
                  <Route component={ruta.component} exact path={ruta.path} />
                ))}
                {/* <Route component={EstadisticasComponente} exact path={'/estadisticas'} />
                <Route component={HppPaginaComponente} exact path={'/hhp'} />
                <Route component={HppPaginaComponente} exact path={'/hhp/:parteHHP'} /> */}
              </Switch>
            </Resetear>
          </Container>
        </MemoryRouter>
      </Router>
    </div>
  );
};

export default PaneldeControlComponente;
