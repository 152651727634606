import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';


const LinkAssy = (
  <Link
    color='inherit'
    href='https://assyvr.xyz/'
    rel='noopener'
    target='_blank'
  >
    {process.env.REACT_APP_NOMBRE}
  </Link>
);
const LinkDW = (
  <Link
    color='inherit'
    href='https://www.fb.com/desarrollowebnodejs/'
    rel='noopener'
    target='_blank'
  >
    Desarrollo Web NodeJS Quito - Ecuador
  </Link>
);

const TerminosMain = () => {
  return (
    <Typography component='div'>
      <Box m={1} textAlign='justify'>
        <Typography paragraph variant='subtitle2'>
          Lea detenidamente estos términos de uso antes de utilizar este sitio
          web {LinkAssy} operado por {LinkDW}. Le proporcionamos estos
          servicios, que están sujetos a las condiciones establecidas a
          continuación en este documento. Cada vez que visita este sitio web,
          utiliza sus servicios o realiza una compra, acepta las siguientes
          condiciones. Es por eso que le instamos a que lo lea detenidamente.
        </Typography>
        <Typography variant='h6'>1. POLÍTICA DE PRIVACIDAD</Typography>
        <Typography paragraph variant='subtitle2'>
          Antes de continuar utilizando nuestro sitio web, le recomendamos que
          lea nuestra Política de Privacidad respecto a nuestra recopilación de datos
          de usuario. Le ayudará a comprender mejor nuestras prácticas.
        </Typography>
        <Typography variant='h6'>
          2. DERECHOS DE AUTOR Y POLÍTICA DMCA
        </Typography>
        <Typography paragraph variant='subtitle2'>
          Como {LinkAssy} pide a otros que respeten sus derechos de propiedad
          intelectual, nosotros también respetamos los derechos de propiedad
          intelectual de los demás. Si cree que el material ubicado o vinculado
          a {LinkAssy} viola sus derechos de autor, le recomendamos que
          notifique a {LinkDW}. Responderemos a todos estos avisos, incluso
          según sea necesario o apropiado, eliminando el material infractor o
          deshabilitando todos los enlaces al material infractor. {LinkAssy}{' '}
          cancelará el acceso de un visitante y el uso del sitio web si, en
          circunstancias apropiadas, se determina que el visitante es un
          infractor reincidente de los derechos de autor u otros derechos de
          propiedad intelectual de ASSYVR u otros. La compilación completa del
          contenido que se encuentra en este sitio web es propiedad exclusiva de
          ASSYVR, con autoría de copyright para esta compilación por {LinkDW}.
        </Typography>
        <Typography variant='h6'>3. COMUNICACIONES</Typography>
        <Typography paragraph variant='subtitle2'>
          Toda la comunicación con nosotros es electrónica. Cada vez que nos
          envíe un correo electrónico o visite nuestro sitio web, se comunicará
          con nosotros. Por la presente, acepta recibir nuestras comunicaciones.
          Si se suscribe a las noticias en nuestro sitio web, recibirá nuestros
          correos electrónicos regulares. Seguiremos comunicándonos con usted
          publicando noticias y avisos en nuestro sitio web y enviándole correos
          electrónicos. También acepta que todos los avisos, divulgaciones,
          acuerdos y otras comunicaciones que le proporcionamos electrónicamente
          cumplen con los requisitos legales de que dichas comunicaciones sean
          por escrito.
        </Typography>
        <Typography variant='h6'>4. LEY APLICABLE</Typography>
        <Typography paragraph variant='subtitle2'>
          Al visitar este sitio web, usted acepta que las leyes de la República
          del Ecuador, sin tener en cuenta los principios de las leyes de
          conflicto, regirán estos términos de servicio, o cualquier disputa de
          cualquier tipo que pueda interponerse entre ASSYVR y usted, o su
          Socios comerciales y asociados.
        </Typography>
        <Typography variant='h6'>5. DESCARGO DE RESPONSABILIDADES</Typography>
        <Typography paragraph variant='subtitle2'>
          Nuestros servicios se proporcionan "tal cual". {LinkAssy} y sus
          proveedores y licenciantes por la presente renuncian a todas las
          garantías de cualquier tipo, expresas o implícitas, incluidas, entre
          otras, las garantías de comerciabilidad, idoneidad para un propósito
          particular y no infracción. Ni {LinkAssy} ni sus proveedores y
          licenciantes garantizan que nuestros Servicios estarán libres de
          errores o que el acceso a los mismos será continuo o ininterrumpido.
          Si realmente está leyendo esto, alabamos su diligencia y preocupación.
          Estaríamos mejor como civilización si a más personas le importara como
          a ti. Usted comprende que descarga u obtiene contenido o servicios a
          través de nuestros Servicios bajo su propia discreción y riesgo.
        </Typography>
        <Typography variant='h6'>6. LICENCIA</Typography>
        <Typography paragraph variant='subtitle2'>
          Le otorgamos una licencia limitada para acceder y hacer uso personal
          de este sitio web. No está permitido descargarlo ni modificarlo. Esto
          puede hacerse solo con nuestro consentimiento por escrito.
        </Typography>
        <Typography variant='h6'>7. CUENTA DE USUARIO</Typography>
        <Typography paragraph variant='subtitle2'>
          Si es propietario de una cuenta en este sitio web, usted es el único
          responsable de mantener la confidencialidad de sus datos privados de
          usuario (nombre de usuario y contraseña). Usted es responsable de
          todas las actividades que ocurran bajo su cuenta o contraseña. ASSYVR
          puede rescindir su acceso a todos o parte de nuestros Servicios en
          cualquier momento, con o sin causa, con o sin aviso, con efecto
          inmediato.
        </Typography>
        <Typography variant='h6'>8. CAMBIOS</Typography>
        <Typography paragraph variant='subtitle2'>
          Estamos constantemente actualizando nuestros Servicios, y eso
          significa que a veces tenemos que cambiar los términos legales bajo
          los cuales se ofrecen nuestros Servicios. Si hacemos cambios que son
          materiales, se lo haremos saber publicando en uno de nuestros sitios
          web, o enviándole un correo electrónico u otra comunicación antes de
          que los cambios surtan efecto.
        </Typography>
        <Typography variant='h6'>9. TRADUCCIÓN</Typography>
        <Typography paragraph variant='subtitle2'>
          Estos Términos de servicio fueron escritos originalmente en español
          (Ecuador.). Podemos traducir estos términos a otros idiomas. En caso
          de conflicto entre una versión traducida de estos Términos de servicio
          y la versión en inglés, prevalecerá la versión en inglés.
        </Typography>
      </Box>
    </Typography>
  );
};

export default TerminosMain;
