import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import JsBarcode from 'react-barcode';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white
  },
  body: {
    fontSize: 16
  }
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 650
  },
  titulo: {
    fontWeight: 600,
    fontStyle: 'italic',
    marginTop: '15px'
  }

});

const RecoleccionReporte = ({ salidaRecolectada }) => {
  const classes = useStyles();

  return (
    <Box>
    <Typography align='center' className={classes.titulo} gutterBottom variant='h6'>Reporte de recolecciones IDF</Typography>
      <TableContainer className='txt' component={Paper} id='pdfdiv'>
        <Table
          aria-label='recolecciones'
          className={classes.table}
          size='medium'
        >
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontWeight: 600 }}>
                Taller
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 600 }}>
                Parte
              </StyledTableCell>
              <StyledTableCell style={{ fontWeight: 600 }}>
                Pulgadas
              </StyledTableCell>

              <StyledTableCell align='center' style={{ fontWeight: 600}}>
                Reporte
              </StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {salidaRecolectada.map((info) =>
              info.items.map((talleres, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component='th' scope='row' style={{ fontWeight: 600 }}>
                    {info.taller}
                  </StyledTableCell>
                  <StyledTableCell>{talleres.parte}</StyledTableCell>
                  <StyledTableCell>{talleres.pulgadas}</StyledTableCell>
                  <StyledTableCell align='center'>
                    <JsBarcode
                      background={'#f5f5f5'}
                      displayValue={false}
                      fontface={'Roboto'}
                      format={'CODE128'}
                      height={25}
                      margin={0}
                      value={talleres.reporte ? talleres.reporte : 'REVISAR'}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

RecoleccionReporte.propTypes = {
  salidaRecolectada: PropTypes.array.isRequired
};

export default RecoleccionReporte;
