import React from 'react';
// import Toolbar from '@material-ui/core/Toolbar';
import Info from './accesoriosInfo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary
  }
}));

const AccesoriosSamsung = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item md={9} sm={12} xs={12}>
        {/* <Typography variant='h6'>Accesorios SAMSUNG</Typography> */}
        <Grid container spacing={3}>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in t labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint paria
              </p>
            </Paper>
          </Grid>
          <Grid item md={3} xs={12}>
            <Paper className={classes.paper}>
              SAMSUNG PRODUCTO 1
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Laborum eaque ducimus
                aspernatur accusantium fuga corrupti iure, unde rerum, nobis laboriosam fugiat illo
                nulla velit sint pariatur eligendi in mollitia! Cupiditate? Mollitia in iste sequi
                nam dolorem magni eveniet ratione minima! Quam veritatis quidem at labore recusandae
                optio cumque debitis ratione. Necessitatibus commodi maiores quidem quia possimus
                esse fugit, aliquid ratione. Debitis quasi vel quam totam, maxime iusto illum vero
                eveniet possimus modi, quia tempore nobis? Assumenda corporis quos sint quasi
                inventore atque eius cupiditate officia! Cumque non mollitia sed modi?
              </p>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Info />
    </Grid>
  );
};

export default AccesoriosSamsung;
