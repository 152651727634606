// App
export const APP_SOLICITA = 'assyvr2020/APP_SOLICITA';
// Estado
export const MENSAJE_ENVIA = 'mensaje/MENSAJE_ENVIA';
// Tema
export const CAMBIAR_TEMA = 'tema/CAMBIAR_TEMA';
export const APP_SOLICITA_CAMBIAR_TEMA = 'tema/APP_SOLICITA_CAMBIAR_TEMA';
// Lenguaje
export const CAMBIAR_LENGUAJE = 'lenguaje/CAMBIAR_LENGUAJE';
export const APP_SOLICITA_CAMBIAR_LENGUAJE = 'lenguaje/APP_SOLICITA_CAMBIAR_LENGUAJE';
// Dialogos
export const MOSTRAR_DIALOGO = 'dialogo/MOSTRAR_DIALOGO';
export const RESET_DIALOGO = 'dialogo/RESET_DIALOGO';
// Inventario
// Idf
export const CAT_PRODUCTOS_SOLICITA = 'productos/CAT_PRODUCTOS_SOLICITA';
export const CAT_PRODUCTOS_RECIBE = 'productos/CAT_PRODUCTOS_RECIBE';
export const CAT_PRODUCTO_AGREGA = 'productos/CAT_PRODUCTO_AGREGA';
export const CAT_PRODUCTOS_ERROR = 'productos/CAT_PRODUCTOS_ERROR';
export const CAMBIA_CAMPO_BUSQUEDA = 'productos/CAMBIA_CAMPO_BUSQUEDA';
export const CAT_PRODUCTOS_RECIBE_BUSQUEDA = 'productos/CAT_PRODUCTOS_RECIBE_BUSQUEDA';
export const RESETEAR_PRODUCTOS = 'productos/RESETEAR_PRODUCTOS';
export const CAT_PRODUCTOS_RECIBE_LISTA = 'productos/CAT_PRODUCTOS_RECIBE_LISTA';
// Buscador
export const MOSTRAR_BUSCADOR = 'buscador/MOSTRAR_BUSCADOR';
export const RESET_BUSCADOR = 'buscador/RESET_BUSCADOR';
// Courier
export const CAT_COURIER_SOLICITA = 'courier/CAT_COURIER_SOLICITA';
export const CAT_COURIER_RECIBE = 'courier/CAT_COURIER_RECIBE';
export const CAT_COURIER_GUIAS = 'courier/CAT_COURIER_GUIAS';
export const CAT_COURIER_AGREGA = 'courier/CAT_COURIER_AGREGA';
export const CAT_COURIER_ERROR = 'courier/CAT_COURIER_ERROR';
export const RESETEAR_COURIER = 'courier/RESETEAR_COURIER';
export const AGREGAR_TABLA_GUIAS = 'courier/AGREGAR_TABLA_GUIAS';

