import React from 'react';
// import Toolbar from '@material-ui/core/Toolbar';
import Info from './accesoriosInfo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary
  }
}));

const AccesoriosNoticias = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item md={9}>
        <Grid container spacing={3}>
          <Grid item>
          <h1>Noticias</h1>

            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem inventore maiores quas asperiores quasi deleniti harum quos delectus beatae eligendi. Voluptates distinctio commodi provident pariatur consequuntur est cum laborum nobis!
            Molestias voluptas consequatur ipsa quidem aliquid sequi, eligendi sint non qui! Deleniti voluptates ut laudantium minima cumque eum, ducimus voluptatem quod accusantium repellat porro quisquam tempora omnis, magni quibusdam nihil?
            Beatae neque molestiae alias natus facere, ullam quaerat sit labore quo eius dolorem in laboriosam quidem deserunt vel cumque excepturi libero at nam quasi assumenda, illum quas! Voluptatum, quas earum.
            Veniam nemo ad sunt esse libero corrupti aspernatur provident asperiores beatae voluptatibus quia odio aut deserunt voluptatem, mollitia eos sit similique assumenda. Iste consectetur vitae, maxime similique unde eum ipsum.
            Repudiandae alias quisquam provident inventore explicabo, iusto perferendis pariatur unde voluptates officiis molestiae commodi quam quidem quasi deserunt quod cumque eligendi fuga, aliquam iure eius tempore. Expedita saepe blanditiis vel.
            Cum facilis, est nesciunt laborum dignissimos molestias magnam quaerat, sint excepturi nulla quasi? Porro ea beatae sed doloremque, autem aliquam tenetur sunt incidunt repellendus distinctio explicabo dolor fugiat maiores obcaecati.x

            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
            quaerat nam recusandae? Vero, in voluptates, qui omnis quaerat
            molestias eius itaque earum dolores non iste assumenda perferendis!
            Ipsa, fugiat eos!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit, neque. Deserunt laboriosam harum voluptates alias repellat. Quod at quia alias ratione odio nostrum vitae necessitatibus eius? Harum debitis consectetur ipsa.
          </Grid>
        </Grid>
      </Grid>
      <Info />
    </Grid>
  );
};

export default AccesoriosNoticias;
