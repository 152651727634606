import React, { useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import { useSelector, useDispatch } from 'react-redux';
import { _cmdgetProductosLista } from '../../base/acciones/inventario.Acciones';
import Cargador from '../../componentes/cargador';

const columns = [
  {
    name: 'parte',
    label: 'Parte',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'descripcion',
    label: 'Descripción',
    options: {
      filter: true,
      sort: true,
      setCellProps: () => ({style: {whiteSpace: 'nowrap'}})
    }
  },
  {
    name: 'ubi',
    label: 'Ubicación',
    options: {
      filter: true,
      sort: true
    }
  },
  {
    name: 'esAccesorio',
    label: 'Accesorio',
    options: {
      filter: true,
      sort: true
    }
  }
];

const options = {
  filter: true,
      filterType: 'dropdown',
      responsive: 'standard',
      fixedHeader: true,
      fixedSelectColumn: true,
      tableBodyHeight: '75vh',
      rowsPerPage: 15
};

const ProductoLista = () => {
  const productoCargando = useSelector(
    (state) => state.inventario.productoCargando
  );
  const catProductosLista = useSelector((state) => state.inventario.catProductosLista);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_cmdgetProductosLista());
  }, [dispatch]);

  return (
    <>
      <Cargador estaCargando={productoCargando} />
      {!productoCargando && (
        <MUIDataTable
          columns={columns}
          data={catProductosLista}
          options={options}
          title={'Listado de partes IDF'}
        />
      )}
    </>
  );
};

export default ProductoLista;
