import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import secretosMain from './config/secretos';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import createStore from './base';
import App from './contenedores/Rutas';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import './reco.css';
import { isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Backdrop from '@material-ui/core/Backdrop';
import Cargador from './componentes/cargador';
import { PersistGate } from 'redux-persist/integration/react';
// const initialState = {}
import { createBrowserHistory } from 'history';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  centro: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  texto: {
    color: theme.palette.common.black,
    textAlign: 'center',
    fontSize: 18,
    padding: '50px'
  },
  backdrop: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.drawer + 1
  },
  aireCargador: {
    paddingBottom: theme.spacing(4)
  }
}));

const Auth = ({ children }) => {
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  if (!isLoaded(auth))
    return (
      <Container>
        <Backdrop className={classes.backdrop} open={true}>
          <div className='sk-circle'>
            <div className='sk-circle1 sk-child' />
            <div className='sk-circle2 sk-child' />
            <div className='sk-circle3 sk-child' />
            <div className='sk-circle4 sk-child' />
            <div className='sk-circle5 sk-child' />
            <div className='sk-circle6 sk-child' />
            <div className='sk-circle7 sk-child' />
            <div className='sk-circle8 sk-child' />
            <div className='sk-circle9 sk-child' />
            <div className='sk-circle10 sk-child' />
            <div className='sk-circle11 sk-child' />
            <div className='sk-circle12 sk-child' />
          </div>
        </Backdrop>
      </Container>
    );
  return children;
};

const firebaseConfig = {
  apiKey: secretosMain().apiKey,
  authDomain: secretosMain().authDomain,
  databaseURL: secretosMain().databaseURL,
  projectId: secretosMain().projectId,
  storageBucket: secretosMain().storageBucket,
  messagingSenderId: secretosMain().messagingSenderId,
  appId: secretosMain().appId
};

firebase.initializeApp(firebaseConfig);

const rfConfig = {
  userProfile: 'cat_usuarios',
  profileParamsToPopulate: [
    ['role:roles'] // populates user's role with matching role object from roles
  ],
  presence: 'presence', // where list of online users is stored in database
  sessions: 'sessions'
  // useFirestoreForProfile: true // Firestore for Profile instead of Realtime DB
  // enableClaims: true // Get custom claims along with the profile
};

const history = createBrowserHistory({ basename: '/idfdev/' });
const initialState = window && window.__INITIAL_STATE__; // set initial state here
// const store = configurarBase(initialState);
const { store, persistor } = createStore(initialState, history);

const render = () => {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <ReactReduxFirebaseProvider config={rfConfig} dispatch={store.dispatch} firebase={firebase}>
          <Auth>
            <PersistGate
              loading={<CircularProgress color='inherit' size={150} />}
              persistor={persistor}
            >
              <App history={history} />
            </PersistGate>
          </Auth>
        </ReactReduxFirebaseProvider>
      </Provider>
    </AppContainer>,
    document.getElementById('assyvr')
  );
};

render();

if (module.hot) {
  module.hot.accept('./contenedores/Rutas', () => {
    render();
  });
}
