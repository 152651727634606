export const traduccionesApp = {
  en: {
    'hero.descripcion': 'Warehouse management software + QR tags',
    'hero.ingreso': 'Log in',
    'hero.ayuda': 'help',
    'hero.info': 'No ads. No contracts.',
    'hero.aqui': 'HERE',
    'hero.bienvenido': 'welcome',
    'hero.bienvenido2': 'To get started please login.',
    
    'inferior.derechos': ' All rights reserved.',
    'inferior.terminos': 'Terms of use',
    'inferior.privacidad': 'Privacy',

    'superior.ingreso': 'log in',
    'superior.registro': 'sign up',
    'superior.ventas': 'SALES CONTACT',
    'superior.es': 'Español',
    'superior.en': 'English'
  },
  ja: {
    'homeComponent.title': 'ホームコンポーネント',
    'homeComponent.english': '英語',
    'homeComponent.japanese': '日本人'
  }};
