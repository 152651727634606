import {
  CAT_COURIER_SOLICITA,
  CAT_COURIER_RECIBE,
  CAT_COURIER_AGREGA,
  CAT_COURIER_ERROR,
  CAT_COURIER_GUIAS,
  RESETEAR_COURIER,
  AGREGAR_TABLA_GUIAS
} from './constantes';
import { toastr } from 'react-redux-toastr';

// SALIDA GUIAS
export const _cmdpostCourierNuevo = (valor) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    axiosInstance
      .post('/api/catalogo_couriers', valor)
      .then((response) => {
        dispatch({
          type: CAT_COURIER_AGREGA,
          payload: response.data
        });
        toastr.success('Courier', 'Guía agregada correctamente.');
        setTimeout(() => {
          dispatch({
            type: RESETEAR_COURIER
          });
        }, 500);
      })
      .catch((error) => {
        console.log(error);
        toastr.error('Courier', `Error al guardar + ${error}`);
        dispatch({
          type: CAT_COURIER_ERROR
        });
      });
    dispatch({
      type: CAT_COURIER_SOLICITA
    });
  };
};

export const _cmdgetCourierLista = () => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    // const hayProductos = getState().inventario.catProductos;
    // const version = getState().estado.catEstado.appVersion;
    axiosInstance
      // .get('/api/catalogo_productos')
      .get('/api/catalogo_couriers')
      // .get(`/posts?skip=${skip}&limit=${limit}`)
      // {"limit": 25,"skip": 0}
      // {"limit": 25,"skip": 10}
      // {"limit": 25,"skip": 20}
      .then((response) => {
        dispatch({
          type: CAT_COURIER_RECIBE,
          payload: response.data
        });
        toastr.success('Couriers', 'Todas las guias cargadas correctamente.');
      })
      .catch((error) => {
        toastr.error('Error con la API', '' + error);
        dispatch({ type: CAT_COURIER_ERROR });
      });

    dispatch({
      type: CAT_COURIER_SOLICITA
    });
    /* setTimeout(() => {
        
        dispatch(_cmdResetearProductos());
      }, 5000); */
  };
};

// ESTADISTICAS
export const _cmdgetGuiasEstadisticas = () => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    axiosInstance
      .get('/api/catalogo_couriers/count')
      .then((response) => {
        dispatch({
          type: CAT_COURIER_GUIAS,
          payload: response.data.count
        });
      })
      .catch((error) => {
        toastr.error('Error', '' + error);
        dispatch({ type: CAT_COURIER_ERROR });
      });
    dispatch({
      type: CAT_COURIER_SOLICITA
    });
  };
};

// INGRESO GUIAS
export const _cmdaddGuiaDHL = guia => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    firebase
      .push('cat_guiasDHL', guia)
      .then(() => {
        toastr.success('Guia DHL', 'Agregada correctamente.');
      });
  };
};

export const _setCampoGuias = (text) => ({
  type: AGREGAR_TABLA_GUIAS,
  payload: text
});
