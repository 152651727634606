import {
  CAMBIAR_LENGUAJE,
  APP_SOLICITA_CAMBIAR_LENGUAJE
} from '../acciones/constantes';

const lenguaje = localStorage.getItem('idioma');

const initialState =
  lenguaje !== null
    ? { idioma: `${lenguaje}`, idiomaCargando: false }
    : { idioma: 'es', idiomaCargando: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case CAMBIAR_LENGUAJE:
      return { 
        ...state, 
        idioma: action.idioma 
      };
    case APP_SOLICITA_CAMBIAR_LENGUAJE:
      return {
        ...state,
        idiomaCargando: !state.idiomaCargando
      };
    default:
      return state;
  }
};
