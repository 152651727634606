import { createStore, applyMiddleware, compose } from 'redux';
import axios from 'axios';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import makeRootReducer  from './reductores';
import { getFirebase } from 'react-redux-firebase';
import { routerMiddleware } from 'connected-react-router';
import { persistStore, persistReducer } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';


// NOTA: createBrowserHistory({basename: '/v2/'}) URL BASE

const ipServidor = `${process.env.REACT_APP_IP_PROD}`;

const persistConfig = {
  key: 'root',
  storage: localStorage,
  blacklist: ['firebase', 'router', 'toastr', 'tema', 'lenguaje', 'dialogo', 'buscador', 'inventario']
}


export default (initialState = {}, history) => {
  const persistedReducer = persistReducer(persistConfig, makeRootReducer(history))

  const defaultOptions = {
    baseURL: 'https://idf.masterdev593.com',
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true
  };

  const axiosInstance = axios.create(defaultOptions);
  axiosInstance.interceptors.request.use(function (config) {
    // const token = store.getState().session.token;
    const token = localStorage.getItem('token');
    config.headers.Authorization = token ? `${token}` : '';
    return config;
  });
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middleware = [
    routerMiddleware(history),
    thunk.withExtraArgument({ getFirebase, axiosInstance }),
    logger
  ];
  const store = createStore(
    persistedReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware))
  );


  const persistor = persistStore(store)

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./reductores', () => {
      store.replaceReducer(makeRootReducer(history));
    });
  }
  return { store, persistor }
}
