import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Delayer from '../../componentes/delayer';
import Inferior from '../../componentes/inferior';
import Buscador from '../../componentes/buscadori';
import Cargador from '../../componentes/cargador';
import SkeletonLoader from '../../componentes/skeleton';
import ProductosHHP from '../../componentes/productos/productosHHP';
import {
  _cmdgetProductos,
  setSearchField,
  _cmdbuscarProductoHHP
} from '../../base/acciones/inventario.Acciones';
import Titulos from '../../componentes/titulos';

const ProgramaPagina = () => {
  const dispatch = useDispatch();
  const catProductos = useSelector((state) => state.inventario.catProductos);
  const catProductoFiltrado = useSelector((state) => state.inventario.catProductoFiltrado);
  const productoCargando = useSelector((state) => state.inventario.productoCargando);
  const campoBusqueda = useSelector((state) => state.inventario.campoBusqueda);
  const abiertoBuscador = useSelector((state) => state.buscador.abiertoBuscador);

  const debouncedSearchTerm = Delayer(campoBusqueda, 1000);

  useEffect(() => {
    debouncedSearchTerm
      ? dispatch(_cmdbuscarProductoHHP(debouncedSearchTerm))
      : dispatch(_cmdgetProductos());
  }, [debouncedSearchTerm, dispatch]);

  const onSearchChange = (event) => {
    var string = event.target.value.toUpperCase();
    var replaced = string.replace("'", '-');
    dispatch(setSearchField(replaced));
  };

  /*   const productosFiltrados = catProductos.filter(producto => {
    return producto.parte.toLowerCase().includes(campoBusqueda.toLowerCase());
  }); */
  const productosFiltrados = catProductoFiltrado.length !== 0 ? catProductoFiltrado : catProductos;

  return (
    <>
      <Titulos titulo='HHP' />
      <Cargador estaCargando={productoCargando} />
      {abiertoBuscador && <Buscador habilitado={productoCargando} searchChange={onSearchChange} />}
      {productoCargando ? <SkeletonLoader /> : <ProductosHHP productos={productosFiltrados} />}
      <Inferior />
    </>
  );
};

/* 
 <VisibleTodoList filter={params.parte || 'SHOW_ALL'} />
function updateUserProfile() {
  return firebase.updateProfile({ rol: 'administrador' });
}
// {!isLoaded(profile) && <Cargador />}
function salir() {
  console.log('voy a salir');

  return firebase.logout();
} */
export default ProgramaPagina;
