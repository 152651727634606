import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

const LinkAssy = (
  <Link
    color='inherit'
    href='https://assyvr.xyz/'
    rel='noopener'
    target='_blank'
  >
    {process.env.REACT_APP_NOMBRE}
  </Link>
);

const LinkDW = (
  <Link
    color='inherit'
    href='https://www.fb.com/desarrollowebnodejs/'
    rel='noopener'
    target='_blank'
  >
    Desarrollo Web NodeJS Quito - Ecuador
  </Link>
);

const PrivacidadMain = () => {
  return (
    <Typography component='div'>
      <Box m={1} textAlign='justify'>
        <Typography component='div' paragraph variant='subtitle2'>
          Su privacidad es de vital importancia para nosotros. Es por ello que{' '}
          {LinkAssy} tiene algunos principios fundamentales:
          <ul>
            <li>
              No compartimos su información personal con nadie, excepto para
              cumplir con la ley y desarrollar nuestros productos o proteger
              nuestros derechos.
            </li>
            <li>
              No almacenamos información personal en nuestros servidores a menos
              que sea requerido para la operación continua de nuestras aplicaciones web.
            </li>
            <li>
              Solo recopilamos información sobre usted si tenemos una razón para
              hacerlo, por ejemplo, para proporcionarle nuevos Servicios, para
              comunicarnos con usted o para mejorar nuestros Servicios.
            </li>
          </ul>
          <Typography variant='h6'>SEGURIDAD</Typography>
          <Typography paragraph variant='subtitle2'>
            Si bien ningún servicio en línea es 100% seguro, trabajamos muy duro
            para proteger su información contra el acceso, uso, alteración o
            destrucción no autorizados, y tomamos medidas razonables para
            hacerlo, como monitorear nuestros Servicios para detectar posibles
            vulnerabilidades y ataques.
          </Typography>
          Nuestro objetivo es hacerlo lo más simple posible para que pueda
          controlar lo que es visible al público. Si tiene alguna duda por favor
          contacte con {LinkDW}
        </Typography>
      </Box>
    </Typography>
  );
};

export default PrivacidadMain;
