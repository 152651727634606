import React, { useEffect } from 'react';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { _cmdgetGuiasEstadisticas } from '../../base/acciones/courier.Acciones';
import { useSelector, useDispatch } from 'react-redux';

const GuiasEstadisticas = () => {
  const dispatch = useDispatch();
  const totalGuias = useSelector((state) => state.courier.guias);

  useEffect(() => {
    dispatch(_cmdgetGuiasEstadisticas());
  }, [dispatch]);

  return (
    <Card style={{ height: '100%' }}>
      <CardContent>
        <Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
          <Grid item>
            <Typography color='textSecondary' gutterBottom variant='h6'>
              GUÍAS ENVIADAS
            </Typography>
            <Typography color='Secondary' variant='h3'>
              {totalGuias}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar style={{ backgroundColor: '#070D0D', height: '56px', width: '56px' }}>
              <LocalShippingIcon />
            </Avatar>
          </Grid>
        </Grid>
        <Box alignItems='center' display='flex' m={1}>
          <ArrowUpwardIcon style={{ color: green[900] }} />
          <Typography
            style={{
              color: green[900],
              marginRight: '8px'
            }}
            variant='body2'
          >
            12%
          </Typography>
          <Typography color='textSecondary' variant='caption'>
            Desde el mes pasado
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default GuiasEstadisticas;
