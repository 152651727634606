import React from 'react';
import PropTypes from 'prop-types';
import Dialogo from '../dialogo';
import Terminos from '../terminos';
import Privacidad from '../privacidad';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { isEmpty } from 'react-redux-firebase';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import Tooltip from '@material-ui/core/Tooltip';
import { push } from 'connected-react-router';
import { useIntl } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  margen: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    },
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  corte: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  positionFixed: {
    bottom: 0,
    top: 'auto',
    zIndex: 1200
  },
  corte2: {
    justifyContent: 'flex-start',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  },
  linea: {
    borderRight: '1px solid',
    paddingRight: '16px',
    borderColor: theme.palette.secondary.main
  }
}));

const Inferior = ({ propColor }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const tipoDialogo = useSelector((state) => state.dialogo.tipo);
  let { path } = useRouteMatch();
  // NOTA: tambien url = useRouteMatch
  return (
    <footer className={classes.root}>
      <Toolbar />
      <AppBar
        className={classes.positionFixed}
        color={propColor}
        position='fixed'
      >
        <Toolbar className={classes.corte2}>
          <Typography variant='caption'>
            <Link
              color='secondary'
              href='https://assyvr.xyz/'
              rel='noopener'
              target='_blank'
            >
              &copy; {new Date().getFullYear()} {process.env.REACT_APP_NOMBRE}
            </Link>
            {intl.formatMessage({
              id: 'inferior.derechos',
              defaultMessage: ' Todos los derechos reservados. '
            })}
            <br />
            Desarrollado por{' '}
            <Link
              color='secondary'
              href='https://fizzvr.me/'
              rel='noopener'
              target='_blank'
            >
              Vladimir Rodríguez
            </Link>{' '}
            para{' '}
            <Link
              color='secondary'
              href='https://www.idfimportadora.com/'
              rel='noopener'
              target='_blank'
            >
              IDF Importadora - Ecuador
            </Link>
          </Typography>
          <Typography
            align='center'
            className={classes.margen}
            variant='caption'
          >
            <Link
              className={classes.linea}
              color='secondary'
              onClick={() => dispatch(push('/'))}
            >
              {process.env.REACT_APP_NOMBRE}
            </Link>
            {!isEmpty(auth) && (
              <>
                <Link
                  className={classes.linea}
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/hhp'))}
                >
                  HHP
                </Link>
                <Link
                  className={classes.linea}
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/ce'))}
                >
                  CE
                </Link>
                <Link
                  className={classes.linea}
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/sap'))}
                >
                  SAP
                </Link>
                <Link
                  className={classes.linea}
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/couriers'))}
                >
                  COURIERS
                </Link>
                <Link
                  className={classes.linea}
                  color='secondary'
                  onClick={() => dispatch(push('/accesorios'))}
                >
                  Accesorios
                </Link>
              </>
            )}

            <Link
              className={classes.linea}
              color='secondary'
              onClick={() => dispatch(_cmdmostrarDialogo('terminos'))}
            >
              {intl.formatMessage({
                id: 'inferior.terminos',
                defaultMessage: 'Términos de uso'
              })}
            </Link>
            <Link
              className={classes.linea}
              color='secondary'
              onClick={() => dispatch(_cmdmostrarDialogo('privacidad'))}
            >
              {intl.formatMessage({
                id: 'inferior.privacidad',
                defaultMessage: 'Privacidad'
              })}
            </Link>
            <Link
              color='secondary'
              onClick={() =>
                dispatch(
                  push({
                    pathname: '/test/busqueda',
                    search: '?q=GH02-18160A#32123qwe123qadc323d3123'
                  })
                )
              }
            >
              <Tooltip title='Estado'>
                <IconButton
                  aria-label='estado'
                  className={classes.margin}
                  size='small'
                >
                  {isEmpty(auth) ? (
                    <DoneIcon fontSize='small' style={{ color: '#FDDC5C' }} />
                  ) : (
                    <DoneAllIcon
                      fontSize='small'
                      style={{ color: '#28ef04' }}
                    />
                  )}
                </IconButton>
              </Tooltip>
            </Link>
          </Typography>
        </Toolbar>
      </AppBar>
      {tipoDialogo === 'terminos' && (
        <Dialogo titulo='Términos de uso'>
          <Terminos />
        </Dialogo>
      )}
      {tipoDialogo === 'privacidad' && (
        <Dialogo titulo='Política de Privacidad'>
          <Privacidad />
        </Dialogo>
      )}
    </footer>
  );
};

Inferior.defaultProps = {
  propColor: 'primary'
};

Inferior.propTypes = {
  propColor: PropTypes.string
};

export default Inferior;
