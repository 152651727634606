import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useSelector } from 'react-redux';
import { useFirebaseConnect } from 'react-redux-firebase';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData(2533383963, 5, 'N/A', '19/07/2021'),
  createData(2533568767, 3, 'N/A', '19/07/2021'),
  createData(5873389960, 2, 'N/A', '19/07/2021'),
  createData(9533343963, 1, 'Caja Abierta', '19/07/2021'),
  createData(4553338391, 1, 'N/A', '19/07/2021'),
  createData(2533568767, 3, 'N/A', '19/07/2021'),
  createData(5873389960, 2, 'La caja se encuentra rota', '19/07/2021'),
  createData(9533343963, 1, 'Abierta', '19/07/2021'),
  createData(4553338391, 1, 'N/A', '19/07/2021')
];

export default function DenseTable() {
  const classes = useStyles();
  // sync /todos from firebase into redux
  useFirebaseConnect('cat_guiasDHL');
  // Connect to redux state using selector hook
  const todos = useSelector((state) => state.firebase.data.cat_guiasDHL);
  /* {JSON.stringify(todos, null, 2) */
  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label='a dense table' className={classes.table} size='small'>
          <TableHead>
            <TableRow>
              <TableCell># Guia</TableCell>
              <TableCell align='right'>Piezas</TableCell>
              <TableCell align='right'>Observación</TableCell>
              <TableCell align='right'>Fecha</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component='th' scope='row'>
                  {row.name}
                </TableCell>
                <TableCell align='right'>{row.calories}</TableCell>
                <TableCell align='right'>{row.fat}</TableCell>
                <TableCell align='right'>{row.carbs}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div>{JSON.stringify(todos, null, 2)}</div>
    </>
  );
}
