import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@material-ui/core';

const data = {
  labels: ['SERVIENTREGA', 'LAAR', 'TRAMACO'],
  datasets: [
    {
      data: [160, 119, 45],
      backgroundColor: [
        'rgba(99, 255, 138, 0.2)',
        'rgba(235, 232, 54, 0.2)',
        'rgba(86, 159, 255, 0.2)'
      ],
      borderColor: [
        '#0cc91c',
        'rgba(255, 206, 86, 1)',
        'rgba(54, 162, 235, 1)'
      ],
      borderWidth: 1
    }
  ]
};

const PieChart = () => (
  <Card>
    <CardContent>
      <Typography align='center' color='textSecondary' gutterBottom variant='h6'>
        COURIERS
      </Typography>
      <Pie data={data} />
    </CardContent>
  </Card>
);

export default PieChart;
