import React from 'react';
// import Toolbar from '@material-ui/core/Toolbar';
import Info from './accesoriosInfo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary
  }
}));

const AccesoriosPromociones = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
        <Grid container spacing={3}>
          <Grid item>
          <h1>Nuestras Promociones</h1>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime
            quaerat nam recusandae? Vero, in voluptates, qui omnis quaerat
            molestias eius itaque earum dolores non iste assumenda perferendis!
            Ipsa, fugiat eos!
            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Suscipit, neque. Deserunt laboriosam harum voluptates alias repellat. Quod at quia alias ratione odio nostrum vitae necessitatibus eius? Harum debitis consectetur ipsa.
          </Grid>
        </Grid>
      </Grid>
      <Info />
    </Grid>
  );
};

export default AccesoriosPromociones;
