import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ReactToPrint from 'react-to-print';
import { useForm } from 'react-hook-form';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import { _cmdpostCourierNuevo } from '../../base/acciones/courier.Acciones';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import logo from '../../recursos/logo.png';
import { DriveEtaOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  logo: {
    margin: '5px 0',
    width: '45px'
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8)
  },
  card: {
    width: '7.62cm',
    height: '5.05cm',
    // height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.text.secondary,
    border: '4px solid black'
  },
  impre: {
    margin: '2%',
    padding: 0
    // fontWeight: 600 // 16:
    // paddingTop: '5%'
  },
  cardContent: {
    flexGrow: 1
  },
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '100%'
    }
  },
  borde: {
    border: '2px solid #080808'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    fontSize: 14
  }
}));

const transportistas = ['LAAR', 'SERVIENTREGA', 'TRAMACO', 'DHL'];

const talleres = [
  'BIT',
  'BIT La Bahía',
  'BIT Cuenca',
  'Logytech',
  'Laser Center',
  'Teland',
  'Elecservitec',
  'Telefunken',
  'Asitec',
  'Serviganga Quito',
  'Serviganga Guayaquil',
  'La Garantia Quito',
  'La Garantia Guayaquil',
  'Servitecnic',
  'Master Electronica',
  'Electronica SA',
  'Electrónica Central',
  'Electrónica Garcia',
  'Electronica Mantilla',
  'Electronica Valverde Milagro',
  'Electronica Valverde El triunfo',
  'IDF/BIT Jorge Zambrano',
  'IDF/TELAND Jorge Zambrano',
  'IDF/LOGYTECH Jorge Zambrano',
  'Tecnioro',
  'Teleradio Quevedo'
];

const destinos = [
  'CUENCA',
  'GUARANDA',
  'AZOGUES',
  'TÚLCAN',
  'EL ORO',
  'LATACUNGA',
  'MACHALA',
  'ESMERALDAS',
  'PUERTO BAQUERIZO MORENO',
  'GUAYAQUIL',
  'IBARRA',
  'LOJA',
  'BABAHOYO',
  'PORTOVIEJO',
  'MACAS',
  'TENA',
  'FRANCISCO DE ORELLANA',
  'PUYO',
  'QUITO',
  'SANTA ELENA',
  'SANTO DOMINGO',
  'NUEVA LOJA',
  'AMBATO',
  'ZAMORA',
  'RIOBAMBA'
];

const Couriers = () => {
  const dispatch = useDispatch();
  const componentRef = useRef();
  const methods = useForm();
  // const { register, handleSubmit, setValue, control } = methods;
  const classes = useStyles();
  const hoy = new Date().toLocaleDateString();
  const [taller, setTaller] = React.useState(talleres[0]);
  const [tallerinputValue, setTallerInputValue] = React.useState('');

  const [capitales, setCapital] = React.useState(destinos[9]);
  const [capitalesinputValue, setCapitalesInputValue] = React.useState('');

  const [transportista, setTransportista] = React.useState(transportistas[0]);
  const [transportistainputValue, setTransportistaInputValue] = React.useState(
    ''
  );

  const [salida, setSalida] = React.useState([]);
  const [mostrarImprimir, setMostraImprimir] = React.useState(true);

  const [courier, setCourier] = useState({
    transportista: 'LAAR',
    taller: '',
    guia: 0,
    origen: 'QUITO',
    destino: '',
    piezas: 1
  });

  const handleChangeDatos = (e) => {
    e.persist();
    setCourier((prevUser) => ({
      ...prevUser,
      [e.target.name]: e.target.value.toUpperCase()
    }));
  };

  const handleGuardar = (e) => {
    e.persist();
    var i = 0;
    var idc = 1;
    while (i < courier.piezas) {
      const clone = { ...courier, id: idc++ };
      console.log('clone', clone);
      setSalida((prevUser) => [...prevUser, clone]);
      i++;
    }
    setMostraImprimir(false);
  };

  return (
    <Container className={classes.cardGrid} component='main' maxWidth='lg'>
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Box
              alignItems='center'
              color='primary.main'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              m={1}
            >
              <Typography variant='h6'>Datos de la etiqueta</Typography>
              <Button
                color='secondary'
                disabled={!mostrarImprimir}
                onClick={handleGuardar}
                style={{ display: 'block' }}
                type='submit'
                variant='contained'
              >
                Generar
              </Button>
            </Box>
            <Box
              alignItems='center'
              display='flex'
              flexDirection='column'
              m={1}
            >
              <Autocomplete
                freeSolo
                fullWidth
                id='transportista'
                inputValue={transportistainputValue}
                onChange={(event, newValue) => {
                  setTransportista(newValue);
                  setCourier((prevUser) => ({
                    ...prevUser,
                    transportista: newValue
                  }));
                }}
                onInputChange={(event, newInputValue) => {
                  setTransportistaInputValue(newInputValue);
                  setCourier((prevUser) => ({
                    ...prevUser,
                    transportista: newInputValue
                  }));
                }}
                options={transportistas}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Transportista'
                    margin='normal'
                    name='taller'
                    variant='outlined'
                  />
                )}
                value={transportista}
              />
              <Autocomplete
                freeSolo
                fullWidth
                id='talleres'
                inputValue={tallerinputValue}
                onChange={(event, newValue) => {
                  setTaller(newValue);
                  setCourier((prevUser) => ({
                    ...prevUser,
                    taller: newValue
                  }));
                }}
                onInputChange={(event, newInputValue) => {
                  setTallerInputValue(newInputValue);
                  setCourier((prevUser) => ({
                    ...prevUser,
                    taller: newInputValue
                  }));
                }}
                options={talleres.sort()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Destinatario / Taller'
                    margin='normal'
                    name='taller'
                    variant='outlined'
                  />
                )}
                value={taller}
              />

              <TextField
                fullWidth
                inputProps={{ style: { textTransform: 'uppercase' } }}
                InputProps={{ inputProps: { min: 0 } }}
                label='Número de guia'
                margin='normal'
                name='guia'
                onChange={handleChangeDatos}
                required={true}
                type='number'
                variant='outlined'
              />
              <TextField
                autoComplete='off'
                fullWidth
                inputProps={{ style: { textTransform: 'uppercase' } }}
                label='Lugar de origen'
                margin='normal'
                name='origen'
                onChange={handleChangeDatos}
                required={true}
                value={courier.origen}
                variant='outlined'
              />
              <Autocomplete
                freeSolo
                fullWidth
                id='destinos'
                inputValue={capitalesinputValue}
                onChange={(event, newValue) => {
                  setCapital(newValue);
                  setCourier((prevUser) => ({
                    ...prevUser,
                    destino: newValue
                  }));
                }}
                onInputChange={(event, newInputValue) => {
                  setCapitalesInputValue(newInputValue);
                  setCourier((prevUser) => ({
                    ...prevUser,
                    destino: newInputValue
                  }));
                }}
                options={destinos.sort()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label='Lugar de destino'
                    margin='normal'
                    name='taller'
                    variant='outlined'
                  />
                )}
                value={capitales}
              />
              <TextField
                fullWidth
                inputProps={{ style: { textTransform: 'uppercase' } }}
                InputProps={{ inputProps: { min: 0, max: 20 } }}
                label='Número de piezas'
                margin='normal'
                name='piezas'
                onChange={handleChangeDatos}
                required={true}
                type='number'
                value={courier.piezas}
                variant='outlined'
              />
            </Box>
          {/*   <label>Output:</label>
            <pre>{JSON.stringify(courier, null, 2)}</pre>
            <label>Output Salida:</label>
            <pre>{JSON.stringify(salida, null, 2)}</pre> */}
          </Paper>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Paper className={classes.paper}>
            <Box
              alignItems='center'
              color='primary.main'
              display='flex'
              flexDirection='row'
              justifyContent='space-between'
              m={1}
            >
              <Typography variant='h6'>Etiqueta</Typography>
              <ReactToPrint
                content={() => componentRef.current}
                onAfterPrint={() => {
                  dispatch(_cmdpostCourierNuevo(courier));
                  setTimeout(() => {
                    setSalida([]);
                    setMostraImprimir(true);
                  }, 500);
                }}
                trigger={() => (
                  <Button
                    color='secondary'
                    disabled={mostrarImprimir}
                    variant='contained'
                  >
                    Imprimir
                  </Button>
                )}
              />
            </Box>
            <div ref={componentRef}>
              {salida &&
                salida.map((producto) => (
                  <>
                    <div className='page-break' />
                    <Card className={classes.card}>
                      <CardContent className={classes.impre}>
                        <Typography
                          component='h6'
                          noWrap
                          style={{ fontWeight: 600 }}
                          variant='body1'
                        >
                          {producto.taller}
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <div>
                            <Typography component='p' variant='body2'>
                              {producto.transportista}
                            </Typography>
                            <Typography component='p' variant='body2'>
                              {'# Guia: '}
                              <span style={{ fontWeight: 600 }}>
                                {producto.guia}
                              </span>
                            </Typography>
                            <Typography component='p' variant='body2'>
                              {'Origen: '}
                              <span style={{ fontWeight: 600 }}>
                                {producto.origen}
                              </span>
                            </Typography>
                            <Typography component='p' variant='body2'>
                              {'Destino: '}
                              <span style={{ fontWeight: 600 }}>
                                {producto.destino}
                              </span>
                            </Typography>
                          </div>
                          <div>
                            <img
                              alt='IDF'
                              className={classes.logo}
                              src={logo}
                            />
                          </div>
                        </div>
                        <Typography
                          component='p'
                          style={{ fontWeight: 600 }}
                          variant='subtitle2'
                        >
                          {'Piezas: '}
                          <span style={{ fontWeight: 600 }}>
                            {producto.id}/{producto.piezas}
                          </span>
                          {'Fecha: '}
                          <span style={{ fontWeight: 600 }}>{hoy}</span>
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                ))}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Couriers;

/* NOTA: PARA IMPRIMIR UN NUMERO */
{/* <Card className={classes.card}>
                      <CardContent className={classes.impre}>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                            paddingTop: '25px'
                          }}
                        >
                          <div>
                            <Typography
                              component='p'
                              style={{ fontWeight: 600, fontSize: 80 }}
                              variant='body2'
                            >
                              {producto.id}
                            </Typography>
                          </div>
                          <div>
                            <img alt='IDF' className={classes.logo} src={logo} />
                          </div>
                        </div>
                      </CardContent>
                    </Card> */}
