import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Recoleccion from '../../componentes/recoleccion';
import Titulos from '../../componentes/titulos';
import { _cmdcargarLoader } from '../../base/acciones/tema.Acciones';
import Cargador from '../../componentes/cargador';

const RecoPagina = () => {
  const estaCargando = useSelector((state) => state.tema.temaCargando);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(_cmdcargarLoader());
  }, [dispatch]);
  return (
    <>
      <Titulos titulo='Recolección' />
      <Cargador estaCargando={estaCargando} />
      <Recoleccion />
    </>
  );
};
export default RecoPagina;
