import {
  CAMBIAR_TEMA,
  APP_SOLICITA_CAMBIAR_TEMA
} from '../acciones/constantes';

const tema = localStorage.getItem('tema');

const initialState =
  tema === 'true'
    ? { tema: true, temaCargando: false }
    : { tema: false, temaCargando: false };

export default (state = initialState, action) => {
  switch (action.type) {
    case CAMBIAR_TEMA:
      return {
        ...state,
        tema: !state.tema
      };
    case APP_SOLICITA_CAMBIAR_TEMA:
      return {
        ...state,
        temaCargando: !state.temaCargando
      };
    default:
      return state;
  }
};
