import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Accesorios from '../../componentes/accesorios';
import Inferior from '../../componentes/inferior';
import Titulos from '../../componentes/titulos';
import { _cmdcargarLoader } from '../../base/acciones/tema.Acciones';
import Cargador from '../../componentes/cargador';

const AccesoriosPagina = () => {
  const estaCargando = useSelector((state) => state.tema.temaCargando);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_cmdcargarLoader());
  }, [dispatch]);

  return (
    <>
      <Titulos titulo='Accesorios' />
      <Cargador estaCargando={estaCargando} />
      <Accesorios />
      {/* <Inferior /> */}
    </>
  );
};

export default AccesoriosPagina;
