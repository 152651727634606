import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { _cmdresetDialogo } from '../../base/acciones/dialogo.Acciones';
import Link from '@material-ui/core/Link';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';
import {
  _cmdRegistroUsuario
} from '../../base/acciones/ingreso.Acciones';
import Cargador from '../../componentes/cargador';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme) => ({
  aire: {
    margin: theme.spacing(1)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const RegistroMain = ({ titulo }) => {
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword1: false
  });
  const abiertoDialogo = useSelector((state) => state.dialogo.abiertoDialogo);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDatosRegistro = (valores) => {
    dispatch(_cmdRegistroUsuario(valores));
  };

  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClickIngreso = () => {
    dispatch(_cmdresetDialogo());
    setTimeout(() => {
      dispatch(_cmdmostrarDialogo('ingreso'));
    }, 500);
  };
  const onClickTerminos = () => {
    dispatch(_cmdresetDialogo());
    setTimeout(() => {
      dispatch(_cmdmostrarDialogo('terminos'));
    }, 500);
  };
  const onClickPrivacidad= () => {
    dispatch(_cmdresetDialogo());
    setTimeout(() => {
      dispatch(_cmdmostrarDialogo('privacidad'));
    }, 500);
  };

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      fullScreen={fullScreen}
      maxWidth='xs'
      onClose={() => dispatch(_cmdresetDialogo())}
      open={abiertoDialogo}
      TransitionComponent={Transition}
    >
      <DialogTitle id='form-dialog-title'>{titulo}</DialogTitle>
      <DialogContent dividers={true}>
        <Box m={1} textAlign='center'>
          <Formik
            initialValues={{ nombre: '', email: '', password: '' }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                handleDatosRegistro(values);
                setSubmitting(false);
              }, 500);
            }}
            validationSchema={Yup.object().shape({
              nombre: Yup.string()
                .required('Correo requerido.')
                .min(5, 'Mínimo 5 caracteres.'),
              email: Yup.string()
                .email('Por favor ingrese un correo válido.')
                .required('Correo requerido.'),
              password: Yup.string()
                .required('La contraseña es requerida.')
                .min(5, 'Mínimo 5 caracteres.')
            })}
          >
            {({ submitForm, isSubmitting, isValid }) => (
              <Form autoComplete='off' noValidate>
                {isSubmitting && <Cargador />}
                <>
                  <Field
                    className={classes.aire}
                    component={TextField}
                    disabled={isSubmitting}
                    fullWidth
                    id='outlined-s1'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {isValid ? (
                            <AccountCircleIcon style={{ color: 'green' }} />
                          ) : (
                            <AccountCircleIcon />
                          )}
                        </InputAdornment>
                      ),
                      color: 'secondary'
                    }}
                    label='Nombre Completo'
                    name='nombre'
                    type='text'
                    variant='outlined'
                  />
                </>
                <Field
                  className={classes.aire}
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  id='outlined-s2'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        {isValid ? (
                          <AlternateEmailIcon style={{ color: 'green' }} />
                        ) : (
                          <AlternateEmailIcon />
                        )}
                      </InputAdornment>
                    ),
                    color: 'secondary'
                  }}
                  label='Correo Electrónico'
                  name='email'
                  type='email'
                  variant='outlined'
                />

                <Field
                  className={classes.aire}
                  component={TextField}
                  disabled={isSubmitting}
                  fullWidth
                  id='password3'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='Ver'
                          onClick={handleClickShowPassword1}
                          onMouseDown={handleMouseDownPassword}
                          style={{ padding: 0 }}
                        >
                          {values.showPassword1 ? (
                            <VisibilityIcon />
                          ) : (
                            <VisibilityOffIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                    color: 'secondary'
                  }}
                  label='Nueva Contraseña'
                  name='password'
                  type={values.showPassword1 ? 'text' : 'password'}
                  variant='outlined'
                />
                <Button
                  className={classes.aire}
                  color='secondary'
                  disabled={isValid ? false : true}
                  fullWidth
                  onClick={submitForm}
                  variant='contained'
                >
                  Regístrate
                </Button>
                <DialogContentText style={{ fontSize: '16px' }}>
                  Al registrarte, aceptas nuestros{' '}
                  <Link color='secondary' onClick={onClickTerminos}>
                    Términos de uso
                  </Link>{' '}
                  y nuestra{' '}
                  <Link color='secondary' onClick={onClickPrivacidad}>
                    Política de privacidad
                  </Link>
                </DialogContentText>
                <Divider className={classes.aire} />
                <DialogContentText style={{ fontSize: '16px' }}>
                  ¿Ya tienes una Cuenta?{' '}
                  <Link onClick={onClickIngreso}>
                    Inicia Sesión
                  </Link>
                </DialogContentText>
              </Form>
            )}
          </Formik>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

RegistroMain.propTypes = {
  titulo: PropTypes.string
};

export default RegistroMain;
