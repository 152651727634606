import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedReduxRedirect } from 'redux-auth-wrapper/history4/redirect';
import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';
import Cargador from '../../componentes/cargador';
import { replace, push } from 'connected-react-router';
import { toastr } from 'react-redux-toastr';
import { _cmdmostrarDialogo, _cmdresetDialogo } from '../../base/acciones/dialogo.Acciones';

const locationHelper = locationHelperBuilder({});

const userIsAuthenticatedDefaults = {
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && !auth.isEmpty,
  authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
    !auth.isLoaded || isInitializing === true,
  wrapperDisplayName: 'UserIsAuthenticated'
};

export const userIsAuthenticated = connectedAuthWrapper(
  userIsAuthenticatedDefaults
);

export const userIsAuthenticatedRedir = connectedReduxRedirect({
  ...userIsAuthenticatedDefaults,
  AuthenticatingComponent: Cargador,
  redirectPath: '/',
  redirectAction: () => (dispatch) => {
    dispatch(push('/'));
    dispatch(_cmdmostrarDialogo('ingreso'));
    setTimeout(() => {
      dispatch(_cmdresetDialogo());
    }, 5000);
    toastr.success(
      'ASSYVRsi',
      'userIsAuthenticatedRedir. | Debes iniciar sesión para acceder al sistema.'
    );
  }
});

export const userIsAdminRedir = connectedReduxRedirect({
  redirectPath: '/',
  allowRedirectBack: false,
  authenticatedSelector: ({ firebase: { profile } }) =>
    profile.isLoaded && !profile.isEmpty && profile.isAdmin,
    predicate: profile => profile.isAdmin,
  wrapperDisplayName: 'UserIsAdmin',
  redirectAction: () => (dispatch) => {
    dispatch(replace('/'));
    // HACK: el siguiente mensaje no debe aparecer si el usuario accede a accesorios. 
    /* toastr.error (
      'Lo siento, necesitas permisos para acceder a este recurso.'
    ); */
  }
});

const userIsNotAuthenticatedDefaults = {
  // Want to redirect the user when they are done loading and authenticated
  authenticatedSelector: ({ firebase: { auth } }) =>
    auth.isLoaded && auth.isEmpty,
  wrapperDisplayName: 'UserIsNotAuthenticated'
};

export const userIsNotAuthenticated = connectedAuthWrapper(
  userIsNotAuthenticatedDefaults
);

export const userIsNotAuthenticatedRedir = connectedReduxRedirect({
  ...userIsNotAuthenticatedDefaults,
  redirectPath: (state, ownProps) =>
    locationHelper.getRedirectQueryParam(ownProps) || '/',
  allowRedirectBack: false,
  redirectAction: () => (dispatch) => {
    dispatch(push('/'));
    toastr.success('ASSYVRno', 'userIsNotAuthenticatedRedir.');
  }
});
