import React, { useRef, useState } from 'react';
import { CSVReader } from 'react-papaparse';
import ReactToPrint from 'react-to-print';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import JsBarcode from 'react-barcode';
import Box from '@material-ui/core/Box';
import RecoleccionesReportes from './recoleccionReporte';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 0,
    padding: 0,
    '&:last-child': { paddingBottom: '0px' }
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left'
    // color: theme.palette.text.secondary
  },
  impre: {
    margin: '4%',
    fontWeight: 600 // 16:9
  },
  title: {
    fontSize: 14
  },
  title2: {
    fontWeight: 600
  },
  cero: {
    margin: 0
  },
  botones: {
    marginRight: theme.spacing(4)
  }
}));

const RecoPapa = () => {
  const classes = useStyles();
  const componentRef = useRef();
  const componentReporte = useRef();
  const [salidaRecolectada, setSalida] = useState([]);
  const [habilito, setHabilito] = useState(true);
  console.log('Recolectada', salidaRecolectada);

  const handleOnDrop = (data) => {
    // console.log('data', data);
    const salida = [];
    let loadingItems = false;
    let taller = '';
    let mesFiscal = '';
    let items = [];
    let vacios = 0;

    data.map((rowx) => {
      if (rowx.data.length) {
        // si el row tiene datos...
        switch (rowx.data[0]) {
          case 'TALLER':
            if (loadingItems) {
              // si estabamos cargando items y encontramos esto, terminamos
              // almacenar lo que llevabamos
              loadingItems = false;
              vacios = 0;
              // terminamos de cargar los items!
              salida.push({
                items,
                taller,
                mesFiscal
              });
              // reset
              items = [];
              mesFiscal = '';
              taller = '';
            }
            // extraer info de la linea taller
            taller = rowx.data[1]; // TELEFUNKEN, etc
            break;
          case 'MES FISCAL':
            // extraer info de la linea MES FISCAL
            mesFiscal = rowx.data[1]; // DICIEMBRE
            break;
          case 'ITEM': // cuando encontramos esta linea, los que siguen son items
            vacios = 0;
            loadingItems = true;
            break;
          default:
            // es un item?
            // TODO: Eliminar los espacios en blanco al cargar el JSON/CSV
            if (parseInt(rowx.data[0])) {
              items.push({
                id: rowx.data[0],
                shipment: rowx.data[1].trim(),
                // imprimo en etiqueta
                reporte: parseInt(rowx.data[2]),
                // imprimo en etiqueta
                tracking: rowx.data[3],
                modelo: rowx.data[4],
                // imprimo pulgadas si no es tarjeta
                // es tarjeta si no tiene serie
                pulgadas: rowx.data[5],
                // imprimo en etiqueta
                parte: rowx.data[6],
                serie: rowx.data[7]
              });
            }
            break;
        }
      }
    });

    // ya salimos del map, terminó.
    if (loadingItems && taller) {
      // estabamos en los items, y tenemos taller (o mas datos)?
      // si todavia tenemos taller (no se ha seteado en ''), es por que era el ultimo
      salida.push({
        items,
        taller,
        mesFiscal
      });
    }

    console.log('llego');
    setSalida(salida);
    setHabilito(false);
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    console.log('---------------------------');
    console.log(data);
    console.log('---------------------------');
  };

  return (
    <>
      <Container className={classes.cardGrid} component='main' maxWidth='lg'>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '15px',
                  alignItems: 'center'
                }}
              >
                <h5>Aquí, el archivo de RECOLECCIONES en formato .CSV</h5>
                <CSVReader
                  addRemoveButton
                  onDrop={handleOnDrop}
                  onError={handleOnError}
                  onRemoveFile={handleOnRemoveFile}
                >
                  <span>
                    Suelta el archivo CSV aquí o haz clic para cargarlo.
                  </span>
                </CSVReader>
              </div>
              <Box display='flex' flexDirection='row' justifyContent='center'>
                <ReactToPrint
                  content={() => componentRef.current}
                  trigger={() => (
                    <Button
                      className={classes.botones}
                      color='secondary'
                      disabled={habilito}
                      variant='contained'
                    >
                      Imprimir Etiquetas
                    </Button>
                  )}
                />
                <ReactToPrint
                  content={() => componentReporte.current}
                  trigger={() => (
                    <Button
                      className={classes.botones}
                      color='secondary'
                      disabled={habilito}
                      variant='contained'
                    >
                      Imprimir Reporte
                    </Button>
                  )}
                />
              </Box>
            </Paper>
          </Grid>
          <Grid item ref={componentReporte} xs={12}>
            <RecoleccionesReportes salidaRecolectada={salidaRecolectada} />
          </Grid>
          {/* <div style={{ display: "none" }}></div> */}
          <div className='print-container' ref={componentRef}>
            {salidaRecolectada.map((info) =>
              info.items.map((talleres) => (
                <>
                  <div className='page-break' />
                  <Grid item key={1} xs={12}>
                    <Card className={classes.ro66}>
                      <CardContent className={classes.root}>
                        <Typography align='center' noWrap variant='subtitle2'>
                          {info.mesFiscal ? info.mesFiscal : 'ENERO'}
                        </Typography>
                        <Typography
                          align='center'
                          noWrap
                          style={{ fontSize: '2em', fontWeight: '600' }}
                        >
                          {info.taller}
                        </Typography>
                        <Box
                          display='flex'
                          flexDirection='row'
                          justifyContent='center'
                        >
                          <JsBarcode
                            background={'#f5f5f5'}
                            displayValue={true}
                            fontface={'Roboto'}
                            format={'CODE128'}
                            height={15}
                            margin={0}
                            value={
                              talleres.reporte ? talleres.reporte : 'REVISAR'
                            }
                          />
                        </Box>
                        <Typography align='center' noWrap variant='subtitle2'>
                          {talleres.tracking}
                        </Typography>
                        <Typography align='center' noWrap variant='subtitle2'>
                          {talleres.serie ? talleres.pulgadas : 'TARJETA'}
                        </Typography>
                        <Typography
                          align='center'
                          noWrap
                          style={{
                            fontWeight: '600',
                            borderBottom: '0.05px dotted red'
                          }}
                          variant='subtitle2'
                        >
                          {talleres.parte}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              ))
            )}
          </div>
        </Grid>
      </Container>
    </>
  );
};

export default RecoPapa;
