import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import { useFirebase } from 'react-redux-firebase';
// eslint-disable-next-line no-unused-vars
import { useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  cursor: {
    color: theme.palette.getContrastText('#FDDC5C'),
    backgroundColor: '#FDDC5C',
    '&:hover': {
      cursor: 'pointer'
    }
  }
}));

const AvatarMain = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const firebase = useFirebase();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const avatarUrl = useSelector((state) => state.firebase.profile.avatarUrl);
  const displayName = useSelector((state) => state.firebase.profile.displayName);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const cmdSalir = () => {
    firebase.logout();
  };
  const cmdTest = () => {
    dispatch(
      push({
        pathname: '/test/busqueda',
        search: '?q=GH02-18160A#32123qwe123qadc323d3123'
      })
    );
  };
  return (
    <>
      <Avatar
        alt={displayName}
        className={classes.cursor}
        onClick={handleClick}
        src={avatarUrl ? avatarUrl : null}
      />

      <Menu
        anchorEl={anchorEl}
        id='simple-menu'
        keepMounted
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={handleClose}>Perfil</MenuItem>
        <MenuItem onClick={handleClose}>Mi Cuenta</MenuItem>
        <MenuItem onClick={handleClose}>Ajuste de la aplicación</MenuItem>
        <Divider />
        <MenuItem onClick={cmdTest}>Ayuda</MenuItem>
        <Divider />
        <MenuItem onClick={cmdSalir}>Cerrar Sesión</MenuItem>
      </Menu>
    </>
  );
};

export default AvatarMain;
