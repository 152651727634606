import {
  CAT_COURIER_SOLICITA,
  CAT_COURIER_RECIBE,
  CAT_COURIER_GUIAS,
  CAT_COURIER_ERROR,
  CAMBIA_CAMPO_BUSQUEDA,
  CAT_PRODUCTOS_RECIBE_BUSQUEDA,
  RESETEAR_COURIER,
  CAT_PRODUCTOS_RECIBE_LISTA
} from '../acciones/constantes';

const initialState = {
  catCourier: [],
  courierCargando: false,
  guias: ''
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAT_COURIER_SOLICITA:
      return {
        ...state,
        courierCargando: true
      };
    case CAT_COURIER_RECIBE:
      return {
        ...state,
        courierCargando: false,
        catCourier: [...action.payload]
      };
    case CAT_COURIER_ERROR:
      return {
        ...state,
        courierCargando: false,
        catCourier: []
      };
    case CAT_COURIER_GUIAS:
      return {
        ...state,
        courierCargando: false,
        guias: action.payload
      };
    case RESETEAR_COURIER:
      return {};
    default:
      return state;
  }
};
