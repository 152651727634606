import React from 'react';
import HeroMain from '../../componentes/hero';
import Titulos from '../../componentes/titulos';

const BienvenidoPagina = () => {
  return (
    <>
      <Titulos titulo='Bienvenido' />
      <HeroMain /> 
    </>
  );
};

export default BienvenidoPagina;
