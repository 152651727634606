import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Inferior from '../../componentes/inferior';
import Cargador from '../../componentes/cargador';
import {
  _cmdgetProductos,
  setSearchField,
  _cmdbuscarProductoCE
} from '../../base/acciones/inventario.Acciones';
import Buscador from '../../componentes/buscadori';
import Delayer from '../../componentes/delayer';
import SkeletonLoader from '../../componentes/skeleton';
import ProductosCE from '../../componentes/productos/productosCE';
import Titulos from '../../componentes/titulos';

const CePagina = () => {
  const productoCargando = useSelector(
    (state) => state.inventario.productoCargando
  );

  const dispatch = useDispatch();
  const catProductos = useSelector((state) => state.inventario.catProductos);
  const catProductoFiltrado = useSelector(
    (state) => state.inventario.catProductoFiltrado
  );
  const campoBusqueda = useSelector((state) => state.inventario.campoBusqueda);
  const abiertoBuscador = useSelector((state) => state.buscador.abiertoBuscador);
  const debouncedSearchTerm = Delayer(campoBusqueda, 1000);

  useEffect(() => {
    debouncedSearchTerm
      ? dispatch(_cmdbuscarProductoCE(debouncedSearchTerm))
      : dispatch(_cmdgetProductos());
  }, [debouncedSearchTerm, dispatch]);

  const onSearchChange = (event) => {
    var string = event.target.value.toUpperCase();
    var replaced = string.replace("'", '-');
    dispatch(setSearchField(replaced));
  };

  const productosFiltrados =
  catProductoFiltrado.length !== 0 ? catProductoFiltrado : catProductos;

  return (
    <>
      <Titulos titulo='CE' />
      <Cargador estaCargando={productoCargando} />
      {abiertoBuscador && <Buscador habilitado={productoCargando} searchChange={onSearchChange} />}
      {productoCargando ? (
        <SkeletonLoader />
      ) : (
        <ProductosCE productos={productosFiltrados} />
      )}
      <Inferior />
    </>
  );
};

export default CePagina;
