import React from 'react';
import PanelControlComponente from '../../componentes/panelControl/';
import Titulos from '../../componentes/titulos';

const PanelControlPagina = () => {
  return (
    <div>
      <Titulos titulo='Panel de Control | ASSYVR' />
      <PanelControlComponente />
    </div>
  );
};

export default PanelControlPagina;
