import { CAMBIAR_LENGUAJE, APP_SOLICITA_CAMBIAR_LENGUAJE } from './constantes';

export const _cmdcambiarLenguaje = (idioma) => {
  return (dispatch, getState) => {
    dispatch({
      type: APP_SOLICITA_CAMBIAR_LENGUAJE
    });
    setTimeout(() => {
      dispatch({
        type: APP_SOLICITA_CAMBIAR_LENGUAJE
      });
      dispatch({
        type: CAMBIAR_LENGUAJE,
        idioma
      });
    }, 500);
    localStorage.setItem('idioma', idioma);
  };
};
