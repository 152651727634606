import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { _cmdresetDialogo } from '../../base/acciones/dialogo.Acciones';
import Slide from '@material-ui/core/Slide';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const Dialogo = ({ titulo, subtitulo, children }) => {
  const [state, setState] = React.useState({
    seleccionado: false
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const abiertoDialogo = useSelector((state) => state.dialogo.abiertoDialogo);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const handlePDF = () => {
    window.print();
  };

  return (
    <Dialog
      aria-describedby='scroll-dialog-description'
      aria-labelledby='scroll-dialog-title'
      disableBackdropClick={true}
      fullScreen={fullScreen}
      fullWidth
      maxWidth='md'
      onClose={() => dispatch(_cmdresetDialogo())}
      open={abiertoDialogo}
      scroll='paper'
      TransitionComponent={Transition}
    >
      <DialogTitle id='responsive-dialog-title'>{titulo}</DialogTitle>
      <DialogContent dividers={false}>
        <DialogContentText component='div'>{children}</DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          margin: '0 8px',
          display: 'flex',
          justifyContent: 'space-between'
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.seleccionado}
                name='seleccionado'
                onChange={handleChange}
              />
            }
            label={
              <p style={{ fontSize: '0.8em' }}>
                Acepto que eh leído y comprendido los Términos de uso y la
                Política de privacidad.
              </p>
            }
          />
        </FormGroup>
        <Button
          color='primary'
          onClick={handlePDF}
          size='small'
          variant='contained'
        >
          Imprimir
        </Button>
        <Button
          color='secondary'
          disabled={!state.seleccionado}
          onClick={() => dispatch(_cmdresetDialogo())}
          size='small'
          variant='contained'
        >
          Acepto
        </Button>
      </DialogActions>
    </Dialog>
  );
};

Dialogo.propTypes = {
  titulo: PropTypes.string.isRequired,
  subtitulo: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default Dialogo;
