import React from 'react';
import Box from '@material-ui/core/Box';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme) => ({
  cardGrid: {
    // paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8)
  },
  cardBotones: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: theme.spacing(4)
  }
}));

const skeletons = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const SkeletonLoader = () => {
  const classes = useStyles();
  return (
    <Container className={classes.cardGrid} maxWidth='lg'>
      <Grid container spacing={4}>
        {skeletons.map((skeleton) => (
          <Grid item key={skeleton} md={4} sm={6} xs={12}>
            <Box key={1} marginRight={0.5} my={5}>
              <Skeleton height={225} variant='rect' />
              <Box pt={0.5}>
                <Skeleton height={80} />
                <Skeleton height={40} />
                <Skeleton height={40} />
                <Skeleton style={{ marginTop: '20px' }} width='40%' />
                <div className={classes.cardBotones}>
                  <Skeleton style={{ marginRight: '10px' }} width='25%' />
                  <Skeleton style={{ marginRight: '10px' }} width='25%' />
                  <Skeleton width='10%' />
                </div>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default SkeletonLoader;
