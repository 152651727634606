import React from 'react';
import ProductoLista from '../../componentes/productos/productoLista';
import Titulos from '../../componentes/titulos';

const TableroPagina = () => {

  return (
    <>
     <Titulos titulo='Tablero de productos' />
     <ProductoLista />
    </>
  );
};

export default TableroPagina;
