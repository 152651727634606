import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Avatar, Box, Card, CardContent, Grid, Typography } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';

const data = {
  labels: ['Guayaquil', 'Quito', 'Cuenca', 'Machala'],
  datasets: [
    {
      label: '# de Guias',
      data: [160, 119, 35, 54],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)'
      ],
      borderWidth: 1
    }
  ]
};

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true
        }
      }
    ]
  }
};

const HorizontalBarChart = () => (
  <Card style={{ height: '100%' }}>
    <CardContent>
      <Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography align='center' color='textSecondary' gutterBottom variant='h6'>
            TOP DESTINOS
          </Typography>
        </Grid>
        <Grid item>
          <Avatar style={{ backgroundColor: '#070D0D', height: '56px', width: '56px' }}>
            <ExploreIcon />
          </Avatar>
        </Grid>
        <Bar data={data} options={options} />
      </Grid>
    </CardContent>
  </Card>
);

export default HorizontalBarChart;
