import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { temaClaro, temaObscuro } from './tema';
import { traduccionesApp } from './traducciones';
import Resetear from './reset';
import BienvenidoPaginaComponente from '../BienvenidoPagina';
import TableroComponente from '../TableroPagina';
import AccesoriosComponente from '../AccesoriosPagina';
import PanelPagina from '../PanelPagina';
import TestPagina from '../Test';
import ReduxToastr from 'react-redux-toastr';
import PropTypes from 'prop-types';
import {
  userIsAuthenticatedRedir,
  userIsNotAuthenticatedRedir,
  userIsAdminRedir
} from './autorizacion';

const PanelPaginaRe = userIsAuthenticatedRedir(PanelPagina);
const TableroPrivado = userIsAuthenticatedRedir(userIsAdminRedir(TableroComponente));
// TODO: Verificar si el usuario publico tiene que loguearse para continuar con la compra.
const AccesoriosPublico = AccesoriosComponente;
const TestPrivadoAdmin = userIsAuthenticatedRedir(userIsAdminRedir(TestPagina));

const InfoPagina = () => (
  <>
    <h1
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '80vh',
        color: 'rgb(10, 67, 105)'
      }}
    >
      ERROR <br /> La dirección que buscas no existe o el servicio solicitado no está disponible
      temporalmente. <br />
      Contacta un Administrador para más información o intente mas tarde.
    </h1>
  </>
);

const App = ({ history }) => {
  const tema = useSelector((state) => state.tema.tema);
  const idioma = useSelector((state) => state.lenguaje.idioma);

  return (
    <IntlProvider defaultLocale='es' locale={idioma} messages={traduccionesApp[idioma]}>
      <ThemeProvider theme={tema ? temaObscuro : temaClaro}>
        <CssBaseline />
        <ConnectedRouter history={history}>
          <Resetear>
            <Switch>
              <Route component={BienvenidoPaginaComponente} exact path='/' />
              <Route component={PanelPaginaRe} path='/dashboard' />
              {/* <Route component={TableroPrivado} path='/tablero' /> */}
              <Route component={TestPrivadoAdmin} path='/test/:busqueda' />
              <Route component={AccesoriosPublico} path='/accesorios' />
              <Route component={InfoPagina} path='*' />
            </Switch>
          </Resetear>
        </ConnectedRouter>
        <ReduxToastr
          closeOnToastrClick
          getState={(state) => state.toastr}
          newestOnTop={false}
          position='bottom-left'
          preventDuplicates
          progressBar
          timeOut={2500}
          transitionIn='fadeIn'
          transitionOut='fadeOut'
        />
      </ThemeProvider>
    </IntlProvider>
  );
};

App.propTypes = {
  history: PropTypes.object
};

export default App;
