import React from 'react';
import Info from './accesoriosInfo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.primary
  }
}));

const AccesoriosSony = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={3}>
      <Grid item xs={9}>
      <h1>Accesorios Sony</h1>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <Paper className={classes.paper}>SONY PRODUCTO 1</Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>SONY PRODUCTO 2</Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>SONY PRODUCTO 3</Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>SONY PRODUCTO 4</Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper className={classes.paper}>SONY PRODUCTO 5</Paper>
          </Grid>
        </Grid>
      </Grid>
      <Info />
    </Grid>
  );
};

export default AccesoriosSony;
