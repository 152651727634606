import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  social: {
    position: 'fixed',
    top: '15%'
  },
  fb: {
    color: '#0069ff'
  }
}));

const Social = () => {
  const classes = useStyles();
  return (
    <div className={classes.social}>
      <Tooltip arrow placement='right' title='Más info en Facebook'>
        <IconButton
          aria-label='facebook'
          className={classes.fb}
          href='https://www.fb.com/desarrollowebnodejs'
          target='_blank'
        >
          <FacebookIcon />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Social;
