import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { fade, makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Container from '@material-ui/core/Container';
import logo from '../../recursos/logo.png';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { useFirebase } from 'react-redux-firebase';
import Avatar from '@material-ui/core/Avatar';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import { _cmdredirigiInicio } from '../../base/acciones/tema.Acciones';
import { isEmpty } from 'react-redux-firebase';
import Grid from '@material-ui/core/Grid';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link as RouterLink,
  useRouteMatch,
  useLocation
} from 'react-router-dom';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { MemoryRouter } from 'react-router';
import Collapse from '@material-ui/core/Collapse';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import { useSelector, useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Resetear from '../../contenedores/Rutas/reset';
import rutasAccesorios from './accesoriosRutas.js';

const breadcrumbNameMap = {
  '/accesorios': 'Accesorios',
  '/accesorios/samsung': 'Samsung',
  '/accesorios/whirpool': 'Whirpool',
  '/accesorios/panasonic': 'Panasonic',
  '/accesorios/sony': 'Sony',
  '/accesorios/otros': 'Otras Marcas',
  '/pp': 'Protection Pro',
  '/promociones': 'Promociones',
  '/nosotros': 'Nosotros',
  '/noticias': 'Noticias',
  '/contacto': 'Contacto'
};

const drawerWidth = 240;

const ListItemLink = ({ to, abrir, children, ...other }) => {
  const [path, setPath] = React.useState('');
  const primary = breadcrumbNameMap[to];
  let location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
    console.log(location.pathname);
  }, [location, setPath]);

  const activetRoute = (route) => {
    return route === path;
  };

  return (
    <ListItem
      button
      component={RouterLink}
      selected={activetRoute(to)}
      to={to}
      {...other}
    >
      <ListItemIcon>{children}</ListItemIcon>
      <ListItemText primary={primary} />
      {abrir != null ? abrir ? <ExpandLess /> : <ExpandMore /> : null}
    </ListItem>
  );
};

ListItemLink.propTypes = {
  abrir: PropTypes.bool,
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1
    }
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  logo: {
    display: 'block',
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    },
    margin: '5px 0',
    width: '90px'
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
    // necessary for content to be below app bar
    // minHeight: '100px',
    /* [theme.breakpoints.down('xs')]: {
      ...theme.mixins.toolbar
    } */
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto'
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit'
  },
  inputInput: {
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '300px',
      '&:focus': {
        width: '350px'
      }
    },
    [theme.breakpoints.down('xs')]: {
      width: '150px',
      '&:focus': {
        width: '160px'
      }
    },
    '&:hover': {
      cursor: 'progress'
    }
  },
  grow: {
    flexGrow: 1
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex'
    }
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none'
    }
  },
  cursor: {
    color: theme.palette.getContrastText('#FDDC5C'),
    backgroundColor: '#FDDC5C',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  root2: {
    display: 'flex',
    flexDirection: 'column',
    width: 360
  },
  lists: {
    backgroundColor: theme.palette.background.paper,
    marginTop: theme.spacing(1)
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

LinkRouter.propTypes = {
  to: PropTypes.string.isRequired
};

export default function MiniDrawer() {
  let { path, url } = useRouteMatch();
  const avatarUrl = useSelector((state) => state.firebase.profile.avatarUrl);
  const displayName = useSelector(
    (state) => state.firebase.profile.displayName
  );
  const auth = useSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();
  const firebase = useFirebase();
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [abrir, setAbrir] = React.useState(false);

  const handleClick = () => {
    setAbrir((prevOpen) => !prevOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
    setAbrir(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setAbrir(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const cmdSalir = () => {
    firebase.logout();
    handleMenuClose();
    dispatch(_cmdredirigiInicio());
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      onClose={handleMenuClose}
      open={isMenuOpen}
    >
      <MenuItem disabled={isEmpty(auth)}>
        <IconButton
          aria-controls='tu perfil'
          aria-haspopup='true'
          aria-label='tu perfil'
          color='inherit'
        >
          <AccountCircle />
        </IconButton>
        <p>Tú Perfil</p>
      </MenuItem>
      <Divider />
      <MenuItem>
        <IconButton
          aria-controls='configuraciones'
          aria-haspopup='true'
          aria-label='configuraciones'
          color='inherit'
        >
          <SettingsIcon />
        </IconButton>
        <p>Configuraciones</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-controls='ayuda'
          aria-haspopup='true'
          aria-label='ayuda'
          color='inherit'
        >
          <ContactSupportIcon />
        </IconButton>
        <p>Ayuda</p>
      </MenuItem>
      <Divider />
      <MenuItem disabled={isEmpty(auth)} onClick={cmdSalir}>
        <IconButton
          aria-controls='cerrar sesion'
          aria-haspopup='true'
          aria-label='cerrar sesión'
          color='inherit'
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Cerrar Sesión</p>
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      onClose={handleMobileMenuClose}
      open={isMobileMenuOpen}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <MenuItem>
        <IconButton aria-label='show 4 new mails' color='inherit'>
          <Badge badgeContent={4} color='secondary'>
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Mensajes</p>
      </MenuItem>
      <MenuItem>
        <IconButton aria-label='show 11 new notifications' color='inherit'>
          <Badge badgeContent={11} color='secondary'>
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notificaciones</p>
      </MenuItem>
      <Divider />
      <MenuItem disabled={isEmpty(auth)}>
        <IconButton
          aria-controls='tu perfil'
          aria-haspopup='true'
          aria-label='tu perfil'
          color='inherit'
          disabled={isEmpty(auth)}
        >
          <AccountCircle />
        </IconButton>
        <p>Tú Perfil</p>
      </MenuItem>
      <Divider />
      <MenuItem>
        <IconButton
          aria-controls='configuraciones'
          aria-haspopup='true'
          aria-label='configuraciones'
          color='inherit'
        >
          <SettingsIcon />
        </IconButton>
        <p>Configuraciones</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          aria-controls='ayuda'
          aria-haspopup='true'
          aria-label='ayuda'
          color='inherit'
        >
          <ContactSupportIcon />
        </IconButton>
        <p>Ayuda</p>
      </MenuItem>
      <Divider />
      <MenuItem disabled={isEmpty(auth)} onClick={cmdSalir}>
        <IconButton
          aria-controls='cerrar sesion'
          aria-haspopup='true'
          aria-label='cerrar sesión'
          color='inherit'
        >
          <ExitToAppIcon />
        </IconButton>
        <p>Cerrar Sesión</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        position='fixed'
      >
          <Toolbar>
            <IconButton
              aria-label='open drawer'
              className={clsx(classes.menuButton, {
                [classes.hide]: open
              })}
              color='inherit'
              edge='start'
              onClick={handleDrawerOpen}
            >
              <MenuIcon />
            </IconButton>
            {/* <img alt='IDF' className={classes.logo} src={logo} /> */}
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput
                }}
                inputProps={{ 'aria-label': 'buscar' }}
                placeholder='¿Que buscas?'
                type='text'
              />
            </div>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <IconButton
                aria-label='show 17 new notifications'
                color='inherit'
              >
                <Badge badgeContent={17} color='secondary'>
                  <AddShoppingCartIcon />
                </Badge>
              </IconButton>

              <Typography
                align='center'
                color='inherit'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginLeft: '15px'
                }}
                variant='h6'
              >
                {'$ 36.79'}
              </Typography>
              <IconButton
                aria-controls={menuId}
                aria-haspopup='true'
                aria-label='account of current user'
                color='inherit'
                edge='end'
                onClick={handleProfileMenuOpen}
                style={{ marginLeft: '15px' }}
              >
                <Avatar
                  alt={displayName}
                  className={classes.cursor}
                  src={avatarUrl}
                />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-controls={mobileMenuId}
                aria-haspopup='true'
                aria-label='show more'
                color='inherit'
                onClick={handleMobileMenuOpen}
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
      <Router>
        <MemoryRouter initialEntries={['/accesorios']} initialIndex={0}>
          <Drawer
            classes={{
              paper: clsx({
                [classes.drawerOpen]: open,
                [classes.drawerClose]: !open
              })
            }}
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open
            })}
            variant='permanent'
          >
            <div className={classes.toolbar}>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? (
                  <ChevronRightIcon />
                ) : (
                  <ChevronLeftIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <nav aria-label='navegacion' className={classes.lists}>
              <List disablePadding>
                <ListItemLink
                  abrir={abrir}
                  onClick={handleClick}
                  to='/accesorios'
                >
                  <MailIcon />
                </ListItemLink>
                <Collapse
                  component='li'
                  in={abrir}
                  timeout='auto'
                  unmountOnExit
                >
                  <List disablePadding>
                    <ListItemLink
                      className={classes.nested}
                      to='/accesorios/samsung'
                    >
                      <InboxIcon />
                    </ListItemLink>
                    <ListItemLink
                      className={classes.nested}
                      to='/accesorios/whirpool'
                    >
                      <InboxIcon />
                    </ListItemLink>
                    <ListItemLink
                      className={classes.nested}
                      to='/accesorios/panasonic'
                    >
                      <InboxIcon />
                    </ListItemLink>
                    <ListItemLink
                      className={classes.nested}
                      to='/accesorios/sony'
                    >
                      <InboxIcon />
                    </ListItemLink>
                    <ListItemLink
                      className={classes.nested}
                      to='/accesorios/otros'
                    >
                      <InboxIcon />
                    </ListItemLink>
                  </List>
                </Collapse>
                <ListItemLink to='/pp'>
                  <MailIcon />
                </ListItemLink>
                <ListItemLink to='/promociones'>
                  <MailIcon />
                </ListItemLink>
                <Divider />
                <ListItemLink to='/nosotros'>
                  <MailIcon />
                </ListItemLink>
                <ListItemLink to='/noticias'>
                  <MailIcon />
                </ListItemLink>
                <ListItemLink onClick={open ? handleDrawerClose : handleDrawerOpen} to='/contacto'>
                  <MailIcon />
                </ListItemLink>
              </List>
            </nav>
          </Drawer>
          <Container className={classes.content} maxWidth='xl'>
            <Toolbar />
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Route>
                  {({ location }) => {
                    const pathnames = location.pathname
                      .split('/')
                      .filter((x) => x);

                    return (
                      <Breadcrumbs aria-label='breadcrumb'>
                        <LinkRouter
                          color='secondary'
                          onClick={() => dispatch(push('/'))}
                        >
                          Inicio
                        </LinkRouter>
                        {pathnames.map((value, index) => {
                          const last = index === pathnames.length - 1;
                          const to = `/${pathnames
                            .slice(0, index + 1)
                            .join('/')}`;

                          return last ? (
                            <Typography color='textPrimary' key={to}>
                              {breadcrumbNameMap[to]}
                            </Typography>
                          ) : (
                            <LinkRouter color='secondary' key={to} to={to}>
                              {breadcrumbNameMap[to]}
                            </LinkRouter>
                          );
                        })}
                      </Breadcrumbs>
                    );
                  }}
                </Route>
              </Grid>
            </Grid>
            <Resetear>
              <Switch>
                {rutasAccesorios.map((ruta) => (
                  <Route component={ruta.component} exact path={ruta.path} />
                ))}
              </Switch>
            </Resetear>
          </Container>
        </MemoryRouter>
      </Router>
    </div>
  );
}
