import { createMuiTheme } from '@material-ui/core/styles';

export const temaClaro = createMuiTheme({
  palette: {
    background: {
      paper: '#f8f8f8'
    },
    type: 'light',
    primary: {
      main: '#070D0D'
    },
    secondary: {
      main: '#FDDC5C'
    }
  },
  typography: {
    fontFamily: [
      'lato',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: 18
  }
});

export const temaObscuro = createMuiTheme({
  palette: {
    background: {
      paper: '#424242'
    },
    type: 'dark',
    primary: {
      main: '#070D0D'
    },
    secondary: {
      main: '#FDDC5C'
    }
  },
  typography: {
    fontFamily: [
      'lato',
      'Arial',
      'sans-serif'
    ].join(','),
    fontSize: 18
  }
});
