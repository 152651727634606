import {
  CAT_PRODUCTOS_SOLICITA,
  CAT_PRODUCTOS_RECIBE,
  CAT_PRODUCTO_AGREGA,
  CAT_PRODUCTOS_ERROR,
  CAMBIA_CAMPO_BUSQUEDA,
  CAT_PRODUCTOS_RECIBE_BUSQUEDA,
  RESETEAR_PRODUCTOS,
  CAT_PRODUCTOS_RECIBE_LISTA
} from './constantes';
import { toastr } from 'react-redux-toastr';
import { push } from 'connected-react-router';
import { _cmdmostrarDialogo } from './dialogo.Acciones';

const toastrOptions = {
  position: 'bottom-left',
  timeOut: 5000,
  showCloseButton: true,
  closeOnToastrClick: true
};

export const _cmdResetearProductos = () => {
  return { type: RESETEAR_PRODUCTOS };
};

export const setSearchField = (text) => ({
  type: CAMBIA_CAMPO_BUSQUEDA,
  payload: text
});

export const _cmdgetProductos = () => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    // const hayProductos = getState().inventario.catProductos;
    // const version = getState().estado.catEstado.appVersion;
    axiosInstance
      // .get('/api/catalogo_productos')
      .get('/api/catalogo_productos_finals?filter=%7B%22limit%22%3A%2025%7D')
      // .get(`/posts?skip=${skip}&limit=${limit}`)
      // {"limit": 25,"skip": 0}
      // {"limit": 25,"skip": 10}
      // {"limit": 25,"skip": 20}
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: CAT_PRODUCTOS_RECIBE,
            payload: response.data
          });
          toastr.success('Inventario', 'Cargado correctamente');
        }, 500);
      })
      .catch((error) => {
        toastr.error('Error con la API', '' + error);
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });

    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
    /* setTimeout(() => {
        
        dispatch(_cmdResetearProductos());
      }, 5000); */
  };
};

export const _cmdgetProductosLista = () => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    // const hayProductos = getState().inventario.catProductos;
    // const version = getState().estado.catEstado.appVersion;
    axiosInstance
      // .get('/api/catalogo_productos')
      .get('/api/catalogo_productos_finals')
      // .get(`/posts?skip=${skip}&limit=${limit}`)
      // {"limit": 25,"skip": 0}
      // {"limit": 25,"skip": 10}
      // {"limit": 25,"skip": 20}
      .then((response) => {
        dispatch({
          type: CAT_PRODUCTOS_RECIBE_LISTA,
          payload: response.data
        });
        toastr.success('Inventario', 'Todas las partes cargadas correctamente.');
      })
      .catch((error) => {
        toastr.error('Error con la API', '' + error);
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });

    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
    /* setTimeout(() => {
        
        dispatch(_cmdResetearProductos());
      }, 5000); */
  };
};

export const _cmdbuscarProductoHHP = (valor) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    /*  NOTA:
    
    const url = `${ROOT_URL}&q=${aParamYouMayNeed}`;
    const request = axios.get(url);
    
    const URLquery = getState().router.location.search;

    let queryString = `?page=${page}&limit=${limit}`;
    if(search){
      queryString += `&search=${search}`;
    } */
    /*     console.log(URLquery);
    console.log('*/ // ////////////////////'); */

    // `/api/catalogo_productos/findOne?filter=%7B%22where%22%3A%7B%22parte%22%3A%22${valor}%22%7D%7D`

    axiosInstance
      .get(
        `/api/catalogo_productos_finals/findOne?filter=%7B%22where%22%3A%7B%22parte%22%3A%22${valor}%22%7D%7D`
      )
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: CAT_PRODUCTOS_RECIBE_BUSQUEDA,
            payload: response.data
          });
          toastr.success('Búsqueda', `Parte encontrada ${valor}`, toastrOptions);
        }, 500);
        dispatch(
          push({
            pathname: `/dashboard/hhp/${valor}`,
            query: {
              parte: valor
            }
          })
        );
      })
      .catch((error) => {
        toastr.error('Error en la búsqueda', 'La parte no existe', toastrOptions);
        dispatch(
          push({
            pathname: `/dashboard/hhp/${valor}`,
            query: {
              parte: valor
            }
          })
        );
        dispatch(_cmdmostrarDialogo('productoNuevo'));
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
  };
};
export const _cmdbuscarProductoCE = (valor) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    axiosInstance
      .get(
        `/api/catalogo_productos_finals/findOne?filter=%7B%22where%22%3A%7B%22parte%22%3A%22${valor}%22%7D%7D`
      )
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: CAT_PRODUCTOS_RECIBE_BUSQUEDA,
            payload: response.data
          });
          toastr.success('Búsqueda', `Parte encontrada ${valor}`, toastrOptions);
        }, 500);
        dispatch(
          push({
            pathname: `/dashboard/ce/${valor}`,
            query: {
              parte: valor
            }
          })
        );
      })
      .catch((error) => {
        toastr.error('Error en la búsqueda', 'La parte no existe', toastrOptions);
        dispatch(
          push({
            pathname: `/dashboard/ce/${valor}`,
            query: {
              parte: valor
            }
          })
        );
        dispatch(_cmdmostrarDialogo('productoNuevo'));
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
  };
};
export const _cmdbuscarProductoSAP = (valor) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    axiosInstance
      .get(
        `/api/catalogo_productos_finals/findOne?filter=%7B%22where%22%3A%7B%22parte%22%3A%22${valor}%22%7D%7D`
      )
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: CAT_PRODUCTOS_RECIBE_BUSQUEDA,
            payload: response.data
          });
          toastr.success('Búsqueda', `Parte encontrada ${valor}`, toastrOptions);
        }, 500);
        dispatch(
          push({
            pathname: `/dashboard/sap/${valor}`,
            query: {
              parte: valor
            }
          })
        );
      })
      .catch((error) => {
        toastr.error('Error en la búsqueda', 'La parte no existe', toastrOptions);
        dispatch(
          push({
            pathname: `/dashboard/sap/${valor}`,
            query: {
              parte: valor
            }
          })
        );
        dispatch(_cmdmostrarDialogo('productoNuevo'));
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
  };
};

export const _cmdpostProductoNuevo = (valor) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    axiosInstance
      .post('/api/catalogo_productos_finals', valor)
      .then((response) => {
        dispatch({
          type: CAT_PRODUCTO_AGREGA,
          payload: response.data
        });
        toastr.success('Inventario', 'Parte agregada correctamente.');
      })
      .catch((error) => {
        console.log(error);
        toastr.error('Inventario', `Error al guardar + ${error}`);
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
  };
};

export const _cmdgetProductosbyID = (id) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    axiosInstance
      .get(`/api/catalogo_productos_finals/${id}`)
      .then((response) => {
        dispatch({
          type: CAT_PRODUCTO_AGREGA,
          payload: response.data
        });
        toastr.success('ID', 'ID');
      })
      .catch((error) => {
        console.table(error);
        toastr.error('Inventario', 'Error al cargar el ID');
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
  };
};

export const _cmdputProductosbyID = (valor) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    const id = getState().inventario.catProductoFiltrado[0].id;
    axiosInstance
      .put(`/api/catalogo_productos_finals/${id}`, valor)
      .then((response) => {
        dispatch({
          type: CAT_PRODUCTO_AGREGA,
          payload: response.data
        });
        toastr.success('ID', 'IDffffffffffffffffffffffffff');
      })
      .catch((error) => {
        console.table(error);
        toastr.error('Inventario', 'Error al modificar');
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
  };
};

export const _cmdpostSeries = (item) => {
  return (dispatch, getState, api) => {
    api
      .post('/api/notes', item)
      .then(() => {
        dispatch({
          type: CAT_PRODUCTO_AGREGA,
          payload: item
        });
        toastr.success('Inventario', 'Parte agregada correctamente.');
      })
      .catch((error) => {
        console.table(error);
        toastr.error('Inventario', 'Error al cargar el inventario');
        dispatch({ type: CAT_PRODUCTOS_ERROR });
      });
    dispatch({
      type: CAT_PRODUCTOS_SOLICITA
    });
  };
};
