import React from 'react';
import Info from './accesoriosInfo';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { Zoom } from 'react-reveal';
import SamsungLogo from '../../recursos/Samsung.png';
import WhirpoolLogo from '../../recursos/Whirpool.png';
import PanasonicLogo from '../../recursos/Panasonic.png';
import SonyLogo from '../../recursos/Sony.png';
import OtrosLogo from '../../recursos/Otros.png';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(4),
    textAlign: 'center',
    color: theme.palette.text.primary
  },
  logo: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    height: '75px',
    maxWidth: '225px'

    /* ,
    [theme.breakpoints.up('md')]: {
      display: 'block'
    } */
  },
  logoo: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
    margin: '5px 0',
    width: '90px'
  },
  links: {
    color: theme.palette.text.primary,
    '&:hover': {
      textDecoration: 'none',
      color: '#FDDC5C'
    }
  }
}));

const IndexAccesorios = () => {
  const classes = useStyles();
  return (
    <Grid container spacing={6}>
      <Grid item sm={12} xs={12}>
        <Grid container justify='center' spacing={6}>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Link className={classes.links} component={RouterLink} to='/accesorios/samsung'>
                <Zoom>
                  <img alt='samsung' className={classes.logo} src={SamsungLogo} />
                </Zoom>
              </Link>
              <Typography color='textSecondary'>
                Disponemos de los mejores accesorios de la marca SAMSUNG
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Link component={RouterLink} to='/accesorios/whirpool'>
                <Zoom>
                  <img alt='samsung' className={classes.logo} src={WhirpoolLogo} />
                </Zoom>
              </Link>
              <Typography color='textSecondary'>
                Disponemos de los mejores repuestos de la marca WHIRPOOL
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Link component={RouterLink} to='/accesorios/panasonic'>
                <Zoom>
                  <img alt='samsung' className={classes.logo} src={PanasonicLogo} />
                </Zoom>
              </Link>
              <Typography color='textSecondary'>
                Disponemos de los mejores repuestos de la marca PANASONIC
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Link component={RouterLink} to='/accesorios/sony'>
                <Zoom>
                  <img alt='samsung' className={classes.logo} src={SonyLogo} />
                </Zoom>
              </Link>
              <Typography color='textSecondary'>
                Disponemos de los mejores repuestos de la marca SONY
              </Typography>
            </Paper>
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <Paper className={classes.paper}>
              <Link component={RouterLink} to='/accesorios/otros'>
                <Zoom>
                  <img alt='samsung' className={classes.logo} src={OtrosLogo} />
                </Zoom>
              </Link>
              <Typography color='textSecondary'>
                Tambien disponemos repuestos de otras marcas
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default IndexAccesorios;
