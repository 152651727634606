import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

const Titulo = ({ titulo }) => {
  return (
    <Helmet>
      <title>{titulo}</title>
    </Helmet>
  );
};

Titulo.propTypes = {
  titulo: PropTypes.string.isRequired
};

export default Titulo;
