import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { go } from 'connected-react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { useFirebase } from 'react-redux-firebase';
import Inferior from '../../componentes/inferior';
import Cargador from '../../componentes/cargador';
import { _cmdcambiarLenguaje } from '../../base/acciones/idioma.Acciones';
import {
  _cmdcambiarTema,
  _cmdcargarLoader
} from '../../base/acciones/tema.Acciones';
// .com/search?q=gh02-18160a#hash

const TestPagina = ({ history }) => {
  const firebase = useFirebase();
  const intl = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(_cmdcargarLoader());
  }, [dispatch]);
  const tema = useSelector((state) => state.tema.tema);
  const estaCargando = useSelector((state) => state.tema.temaCargando);
  const pathname = useSelector((state) => state.router.location.pathname);
  const search = useSelector((state) => state.router.location.search);
  const hash = useSelector((state) => state.router.location.hash);
  const key = useSelector((state) => state.router.location.key);
  const query = useSelector((state) => state.router.location.query);
  // const locales = useSelector((state) => state.locales);
  //
  const cmdSalir = () => {
    firebase.logout();
  };

  return (
    <div>
      <Cargador estaCargando={estaCargando} />
        <p>
          <h1>
            <FormattedMessage
              defaultMessage='ESPALOL APP'
              id='homeComponent.title'
            />
          </h1>
          <div>{`LOCALIZACION ACTUAL: ${intl.locale}`}</div>
        </p>
        <select
          onChange={(e) => dispatch(_cmdcambiarLenguaje(e.target.value))}
          value={intl.locale}
        >
          <option key={1}>en</option>
          <option key={2}>ja</option>
          <option key={3}>es</option>
        </select>
        <div>
          <FormattedMessage defaultMessage='Rey' id='homeComponent.title' />
          <button
            onClick={() => {
              dispatch(_cmdcambiarLenguaje('en'));
            }}
            type='button'
          >
            <FormattedMessage
              defaultMessage='ESte es el mensaje por default'
              id='homeComponent.english'
            />
          </button>
          <button
            onClick={() => {
              dispatch(_cmdcambiarLenguaje('ja'));
            }}
            type='button'
          >
            <FormattedMessage
              defaultMessage='que tal como estas'
              id='homeComponent.japanese'
            />
          </button>
        </div>
        <div>
          <h1>{JSON.stringify(query, null, 2)}</h1>
          <h1>{pathname} ; pathname</h1>
          <h2>{search} ; search</h2>
          <h3>{hash} ; hash</h3>
          <h3>{key} ; key</h3>
          <button onClick={() => dispatch(go(-1))}>login</button>
          <button onClick={cmdSalir}>salir</button>
        </div>
        <div>
          <p>
            TEMA OBCURO SI/NO:
            {String(tema)}
          </p>
          <div>
            <Button
              color='primary'
              onClick={() => dispatch(_cmdcambiarTema())}
              variant='contained'
            >
              Cambiar Tema Dark/Light + {String(tema)}
            </Button>
          </div>
          <div>
            <Button variant='contained'>Default</Button>
            <Button color='primary' variant='contained'>
              Primary
            </Button>
            <Button color='secondary' variant='contained'>
              Secondary
            </Button>
            <Button disabled variant='contained'>
              Disabled
            </Button>
            <Button
              color='primary'
              href='#contained-buttons'
              variant='contained'
            >
              Link
            </Button>
          </div>
          <div>
            <Button variant='outlined'>Default</Button>
            <Button color='primary' variant='outlined'>
              Primary
            </Button>
            <Button color='secondary' variant='outlined'>
              Secondary
            </Button>
            <Button disabled variant='outlined'>
              Disabled
            </Button>
            <Button color='primary' href='#outlined-buttons' variant='outlined'>
              Link
            </Button>
          </div>
          <Typography component='div'>
            <Box m={1} textAlign='justify'>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Debitis
              illo id blanditiis aperiam culpa ducimus consequatur voluptas
              veniam. Itaque exercitationem obcaecati neque! Et dolorum fugit
              ipsa itaque eveniet nihil quae. Dolorum dolor aperiam doloremque
              eius voluptate! Suscipit temporibus voluptatibus molestiae
              laudantium recusandae nemo illo, quidem, cum nulla voluptate
              obcaecati dolore pariatur natus consectetur similique! Provident
              beatae repellat atque quia labore! Molestias eum in cum? Itaque
              omnis ullam, suscipit pariatur, eligendi delectus totam distinctio
              architecto saepe sapiente, consequuntur explicabo. Rerum obcaecati
              incidunt placeat dignissimos tempore autem excepturi esse cum
              facilis maiores. Ad iusto est pariatur voluptatum quidem? Quis
              officia itaque suscipit ipsum culpa in nisi rerum dolorem vero et
              facere labore, voluptates fugit deleniti eligendi vel enim impedit
              molestias. Consequatur, assumenda! Possimus illum alias
              reiciendis, quidem aliquam a quasi ullam eaque cupiditate
              exercitationem explicabo porro at labore asperiores saepe libero,
              doloribus eligendi quisquam! At delectus omnis beatae eveniet
              error, provident facilis? Nesciunt cumque doloremque atque
              voluptate iusto quisquam repellat, quaerat labore iste, dicta
              eaque ducimus ipsam recusandae a reiciendis? Atque aut libero
              inventore nulla aliquid rerum qui odio, odit placeat harum? Quo
              libero alias praesentium amet cum ipsam. Laudantium velit unde
              accusantium dolores quo corrupti nesciunt voluptates animi
              architecto at, eligendi praesentium reiciendis repudiandae
              deleniti quibusdam voluptatibus ratione! Totam, temporibus
              adipisci! Ducimus atque eaque quam, alias sapiente voluptatibus
              reiciendis impedit dolore. Nam, eius officia. Quasi deserunt
              veniam praesentium in unde nulla assumenda eius delectus quaerat.
              Nesciunt ipsa beatae illo ex fugit? Ratione a officiis, expedita
              perspiciatis quia repellendus temporibus iusto, animi aliquid
              quod, repudiandae omnis? Dolor, impedit! Debitis cumque optio
              sequi possimus, voluptatum consequuntur, facilis reprehenderit
              quis amet ipsum voluptatibus odio. Exercitationem reiciendis
              dolore, odit illum excepturi, nam nobis veritatis quasi autem
              provident sint ea animi itaque quae odio. Tenetur, omnis aliquam.
              Sequi fuga eum sed nesciunt illo et adipisci? Quos. Lorem ipsum
              dolor sit, amet consectetur adipisicing elit. Cumque modi
              distinctio in velit repellendus! Libero aliquid quaerat, quia
              architecto possimus voluptatibus. Iure ab labore fugiat inventore
              commodi sint id quidem. Optio excepturi quibusdam officiis nihil,
              esse laudantium, perferendis culpa asperiores ipsum, aut et modi
              aperiam! Dolorem odio necessitatibus nesciunt itaque? Saepe esse
              labore id tempore optio architecto, reprehenderit soluta officiis?
              Possimus officia dolores excepturi dolore inventore accusamus
              corrupti, eos itaque quia voluptate iste architecto voluptatem at
              cum nobis aut deserunt a voluptatibus tempora ex in veritatis
              perspiciatis atque iusto! Distinctio? Saepe dignissimos cupiditate
              vero quod delectus eum quam. Deserunt quibusdam perspiciatis
              impedit iste nam consequatur expedita dicta placeat debitis
              reiciendis. Voluptate quaerat molestiae quidem possimus facilis
              labore, quasi iste aspernatur. Quis dolor saepe eligendi
              voluptatibus cum ut? Ratione, ad? Numquam nihil excepturi est
              error recusandae cum molestiae optio quas, impedit quibusdam.
              Consequuntur nostrum commodi enim doloremque voluptas sint
              provident unde? Nam, voluptates obcaecati! Laborum repudiandae
              molestiae molestias enim blanditiis. Aperiam illum aut, dolor vel
              quo omnis distinctio iste mollitia, ab aliquam dicta. Nulla
              mollitia cum, vel doloribus recusandae modi at! Rerum consequuntur
              perspiciatis itaque inventore cumque ad! Aliquam natus, quae
              labore magnam praesentium qui incidunt excepturi fuga nisi ex
              harum, saepe dolorum soluta enim aspernatur? Accusantium aperiam
              dolorem necessitatibus nostrum! Officiis eveniet recusandae
              officia ea enim voluptas excepturi facere nobis in magni aut
              laborum, rerum expedita, nam ex quod. Architecto, a. Fugit nam a
              animi similique deleniti aut error enim! Laboriosam velit
              veritatis eos quas fuga suscipit provident ipsam qui quibusdam
              minima facere consequuntur animi aspernatur deleniti molestias,
              nostrum itaque aperiam possimus obcaecati? At natus quia deserunt
              obcaecati, odio esse. Ipsum ratione accusantium nulla nostrum
              minus amet odio maiores debitis beatae corporis. Quo repellat
              accusamus, voluptate libero nam est reprehenderit quis
              consequuntur voluptatibus provident corporis deleniti, vitae velit
              iure quasi?
            </Box>
            <Box m={1} textAlign='left'>
              Left aligned text.
            </Box>
            <Box m={1} textAlign='center'>
              Center aligned text.
            </Box>
            <Box m={1} textAlign='right'>
              Right aligned text.
            </Box>
            <Box m={1} textAlign='right'>
              Right aligned text.
            </Box>
            <Box m={1} textAlign='right'>
              Right aligned text.
            </Box>
            <Box m={1} textAlign='right'>
              Right aligned text.
            </Box>
          </Typography>
          <div>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Switch
                    checked={tema}
                    name='checkedA'
                    onChange={() => dispatch(_cmdcambiarTema())}
                  />
                }
                label={`Tema Obscuro:  ${tema ? 'SI' : 'NO'}`}
              />
            </FormGroup>
          </div>
        </div>
        <Inferior />
    </div>
  );
};

TestPagina.propTypes = {
  history: PropTypes.object
};

export default TestPagina;
