import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import IconButton from '@material-ui/core/IconButton';
import { useSelector, useDispatch } from 'react-redux';
import {
  _cmdcambiarTema,
  _cmdcargarLoader
} from '../../base/acciones/tema.Acciones';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '100%'
    }
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputRoot: {
    color: 'inherit',
    display: 'flex'
  },
  inputInput: {
    textTransform: 'uppercase',
    letterSpacing: '0.05em',
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    maxWidth: '100%',
    width: '100%',
    '&:hover': {
      cursor: 'progress'
    }
  },
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(0, 2),
    display: 'flex',
    flexBasis: 420
  },
  icon: {
    marginRight: theme.spacing(0, 2),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  }
}));

const BuscadorControlado = ({ searchChange, habilitado }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const tema = useSelector((state) => state.tema.tema);
  useEffect(() => {
    dispatch(_cmdcargarLoader());
  }, [dispatch]);
  return (
    <section>
      <AppBar
        position='static'
        style={{ borderBottom: '0.80px solid #FDDC5C' }}
      >
        <Toolbar>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              autoFocus
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput
              }}
              disabled={habilitado}
              inputProps={{ 'aria-label': 'buscar' }}
              onChange={searchChange}
              placeholder='Buscador...'
              type='text'
            />
          </div>
          <Tooltip title={`${tema ? 'Tema Obscuro' : 'Tema Claro'}`}>
            <IconButton
              aria-label='tema'
              color='secondary'
              onClick={() => dispatch(_cmdcambiarTema())}
            >
              {tema ? <Brightness4Icon /> : <BrightnessHighIcon />}
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
    </section>
  );
};

/* onKeyPress={(e) => {
  if (e.key === 'Enter') {
    searchChange();
    // write your functionality here
  }
}} */

BuscadorControlado.defaultProps = {
  habilitado: false
};
BuscadorControlado.propTypes = {
  searchChange: PropTypes.func.isRequired,
  habilitado: PropTypes.bool.isRequired
};

export default BuscadorControlado;
