/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import MUIDataTable, { TableFilterList } from 'mui-datatables';
import { useSelector, useDispatch } from 'react-redux';
import { _cmdgetCourierLista } from '../../base/acciones/courier.Acciones';
import Cargador from '../../componentes/cargador';
import Chip from '@material-ui/core/Chip';
import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';

const columns = [
  {
    name: 'guia',
    label: '# Guía',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'transportista',
    label: 'Transportista',
    options: {
      filter: false,
      sort: true,
      setCellProps: () => ({ style: { whiteSpace: 'nowrap' } }),
      filterOptions: {
        names: ['a', 'b', 'c', 'Business Analyst']
      }
    }
  },
  {
    name: 'taller',
    label: 'Taller',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'origen',
    label: 'Origen',
    options: {
      filter: false,
      sort: true,
      display: false
    }
  },
  {
    name: 'destino',
    label: 'Destino',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'piezas',
    label: 'Piezas',
    options: {
      filter: false,
      sort: false,
      display: false
    }
  },
  {
    name: 'fecha',
    label: 'Fecha',
    options: {
      filter: true,
      sort: true,
      customFilterListOptions: { render: (v) => `IDF - Guias del día: ${v}` },
      customBodyRender: (value) => new Date(value).toLocaleDateString()
    }
  }
];

const options = {
  download: false,
  pagination: false,
  elevation: 0,
  viewColumns: false,
  filter: true,
  filterType: 'dropdown',
  responsive: 'standard',
  fixedHeader: true,
  fixedSelectColumn: true,
  tableBodyHeight: '75vh',
  rowsPerPage: 15,
  onFilterChange: (changedColumn, filterList) => {
    console.log(changedColumn, filterList);
  },
  textLabels: {
    body: {
      noMatch: 'Lo siento, no se encuentran registros',
      toolTip: 'Ordenar'
    },
    pagination: {
      next: 'Página Siguiente',
      previous: 'Página Anterior',
      rowsPerPage: 'Filas por página:',
      displayRows: 'de'
    },
    toolbar: {
      search: 'Buscar',
      downloadCsv: 'Descargar CSV',
      print: 'Imprimir',
      viewColumns: 'Ver Columnas',
      filterTable: 'Filtrar tabla'
    },
    filter: {
      all: 'Todos',
      title: 'FILTRAR',
      reset: 'RESETEAR'
    },
    viewColumns: {
      title: 'Ver Columnas',
      titleAria: 'Mostrar/Ocultar Columnas de la Tabla'
    },
    selectedRows: {
      text: 'fila(s) selecionas',
      delete: 'Eliminar',
      deleteAria: 'Eliminar Filas Seleccionadas'
    }
  }
};

const CustomChip = (props) => {
  const { label, onDelete, columnNames, className, index } = props;
  return (
    <Chip
      className={className}
      color='primary'
      label={label}
      onDelete={onDelete}
      variant='outlined'
    />
  );
};

const CustomTooltip = (props) => {
  return (
    <MuiTooltip
      interactive={true}
      leaveDelay={250}
      title={props.title}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 250 }}
    >
      {props.children}
    </MuiTooltip>
  );
};

const CustomCheckbox = (props) => {
  let newProps = Object.assign({}, props);
  newProps.color = props['data-description'] === 'row-select' ? 'secondary' : 'primary';

  if (props['data-description'] === 'row-select') {
    return <Radio {...newProps} />;
  } else {
    return <Checkbox {...newProps} />;
  }
};

const CustomFilterList = (props) => {
  return <TableFilterList {...props} ItemComponent={CustomChip} />;
};

const CouriersGuias = () => {
  const courierCargando = useSelector((state) => state.courier.courierCargando);
  const catCourier = useSelector((state) => state.courier.catCourier);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_cmdgetCourierLista());
  }, [dispatch]);

  return (
    <>
      <Cargador estaCargando={courierCargando} />
      {!courierCargando && (
        <MUIDataTable
          columns={columns}
          components={{
            TableFilterList: CustomFilterList,
            Tooltip: CustomTooltip,
            Checkbox: CustomCheckbox
          }}
          data={catCourier}
          options={options}
          title={'Listado de guias IDF'}
        />
      )}
    </>
  );
};

export default CouriersGuias;
