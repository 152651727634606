import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import HeroSlider, { Slide, OverlayContainer } from 'hero-slider';
import { toastr } from 'react-redux-toastr';
import { Zoom } from 'react-reveal';
import { useIntl } from 'react-intl';
import Superior from '../superior';
import Inferior from '../inferior';
import Social from '../social';

import { useSelector } from 'react-redux';
// import logo from '../../estaticos/logo.png';
import qrcode from '../../estaticos/qr-code2.png';
import imagen1 from '../../estaticos/6.jpg';
import imagen2 from '../../estaticos/8.jpg';
import imagen3 from '../../estaticos/7.jpg';
import imagen4 from '../../estaticos/1.jpg';
import imagen5 from '../../estaticos/9.jpg';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import { push } from 'connected-react-router';
import { isEmpty } from 'react-redux-firebase';

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1)
  },
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(1) * 10
    }
  },
  logo: {
    margin: theme.spacing(1),
    height: '100vw',
    maxHeight: 200,
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  text: {
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundImage: "url('https://picsum.photos/600/900/?random')",
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    padding: 0
  },
  title: {
    letterSpacing: '.7rem',
    textIndent: '.7rem',
    fontWeight: 600,
    [theme.breakpoints.only('xs')]: {
      fontSize: 28
    },
    whiteSpace: 'nowrap'
  },
  headline: {
    paddingLeft: theme.spacing(1) * 4,
    paddingRight: theme.spacing(1) * 4,
    marginTop: theme.spacing(1),
    maxWidth: 500,
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  button: {
    marginTop: theme.spacing(1) * 3,
    marginLeft: theme.spacing(1)
  }
}));

/* 
// NOTA: metodos del heroslider
onAfterChange={(nextSlide) => console.log('onAfterChange', nextSlide)}
onBeforeChange={(previousSlide, nextSlide) =>
  console.log('onBeforeChange', previousSlide, nextSlide)
}
onChange={(nextSlide) => console.log('onChange', nextSlide)} 
*/

const opciones = {
  icon: <ThumbUpIcon />,
  position: 'top-center'
};

const HeroMain = () => {
  const intl = useIntl();
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const dispatch = useDispatch();

  const LinkUno = (
    <Link
      color='textSecondary'
      href='https://www.fb.com/desarrollowebnodejs/'
      rel='noopener'
      target='_blank'
    >
      {intl.formatMessage({
        id: 'hero.aqui',
        defaultMessage: 'AQUÍ'
      })}
    </Link>
  );

  const toastrOptions = {
    showCloseButton: false,
    component: (
      <p style={{ textAlign: 'justify' }}>
        Debe <b>registrarse</b> para obtener todas las funcionalidades de la aplicación. Si desea{' '}
        <i>ADQUIRIR</i> el programa, contactar {LinkUno}.
      </p>
    )
  };
  useEffect(() => {
    isEmpty(auth) &&
      toastr.success(
        `${intl
          .formatMessage({
            id: 'hero.bienvenido',
            defaultMessage: 'bienvenido'
          })
          .toUpperCase()}`,
        `${intl.formatMessage({
          id: 'hero.bienvenido2',
          defaultMessage: 'Para comenzar por favor inicie sesión.'
        })}`,
        opciones
      );
  }, [auth, intl]);

  return (
    <>
      <Social />
      <Superior propColor='transparent' />
      <div className={classes.container1}>
        <div className={classes.text}>
          <div style={{ display: 'flex' }}>
            <Zoom>
              <Typography align='center' className={classes.title} color='secondary' variant='h3'>
                {process.env.REACT_APP_NOMBRE}
              </Typography>
              <Typography
                align='center'
                className={classes.jh}
                color='secondary'
                style={{ fontSize: '15px' }}
              >
                &copy;
              </Typography>
            </Zoom>
          </div>
          <Zoom delay={800}>
            <Typography className={classes.headline} color='inherit' gutterBottom variant='h4'>
              {intl.formatMessage({
                id: 'hero.descripcion',
                defaultMessage: 'Software de gestión de bodega + etiquetas QR'
              })}
            </Typography>
          </Zoom>
          <div style={{ textAlign: 'center' }}>
            {isEmpty(auth) ? (
              <Button
                className={classes.button}
                color='secondary'
                onClick={() => dispatch(_cmdmostrarDialogo('ingreso'))}
                startIcon={<VerifiedUserIcon />}
                variant='contained'
              >
                {intl.formatMessage({
                  id: 'hero.ingreso',
                  defaultMessage: 'Iniciar Sesión'
                })}
              </Button>
            ) : (
              <Button
                className={classes.button}
                color='secondary'
                onClick={() => dispatch(push('/dashboard'))}
                startIcon={<PersonPinIcon />}
                variant='contained'
              >
                Panel de Control
              </Button>
            )}
            <Button
              className={classes.button}
              color='primary'
              onClick={() =>
                toastr.warning(
                  `${intl
                    .formatMessage({
                      id: 'hero.ayuda',
                      defaultMessage: 'ayuda'
                    })
                    .toUpperCase()}`,
                  toastrOptions
                )
              }
              startIcon={<HelpOutlineIcon />}
              variant='contained'
            >
              {intl.formatMessage({
                id: 'hero.ayuda',
                defaultMessage: 'Ayuda'
              })}
            </Button>
            <p style={{ fontWeight: '600', fontSize: '0.75em' }}>
              {intl.formatMessage({
                id: 'hero.info',
                defaultMessage: 'Sin anuncios. Sin contratos.'
              })}
            </p>
          </div>
        </div>
      </div>
      <Inferior propColor='transpa  rent' />
    </>
  );
};

export default HeroMain;
