import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import DlgProductoNuevo from './productoNuevo';
import DlgProductoDetalle from './productoDetalle';
import { useSelector, useDispatch } from 'react-redux';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';
import { _cmdgetProductosbyID } from '../../base/acciones/inventario.Acciones';
import Divider from '@material-ui/core/Divider';
import { useParams } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  botonEspacio: {
    justifyContent: 'flex-end'
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  impre: {
    margin: '4%',
    fontWeight: 600 // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  centrar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
  bordeB: {
    marginTop: '5px'
  },
  containedSizeLarge: {
    padding: '40px 90px'
  },
  divi: {
    backgroundColor: '#070d0d',
    height: '2px',
    '@media print': {
      overflowY: 'visible',
      boxShadow: 'none'
    }
  },
  divi2: {
    backgroundColor: '#070d0d',
    '@media print': {
      overflowY: 'visible',
      boxShadow: 'none'
    }
  },
  printInput: {
    '@media print': {
      color: '#070d0d'
    }
  }
}));

const ProductosCE = ({ productos }) => {
  const [serie, setSerie] = useState('');
  const [origen, setOrigen] = useState('KOREA');
  let { parteCE } = useParams();
  const dispatch = useDispatch();
  const campoBusqueda = useSelector((state) => state.inventario.campoBusqueda);
  const tipoDialogo = useSelector((state) => state.dialogo.tipo);
  const classes = useStyles();
  const componentRef = useRef();
  const hoy = new Date().toLocaleDateString();

  const onClickProductoDetalle = (id) => {
    dispatch(_cmdgetProductosbyID(id));
    setTimeout(() => {
      dispatch(_cmdmostrarDialogo('productoDetalle'));
    }, 500);
  };
  const handleChangeSerie = (event) => {
    setSerie(event.target.value);
  };
  const handleChangeOrigen = (event) => {
    setOrigen(event.target.value);
  };
  return (
    <Container className={classes.cardGrid} component='main' maxWidth='lg'>
      <Grid container spacing={4}>
        {productos.length === 0 && (
          <Typography
            align='center'
            className={classes.centrar}
            color='error'
            component='div'
            variant='h3'
          >
            Error al conectarse con la API remota. Por favor contacte con un
            administrador.
          </Typography>
        )}
        {productos &&
          productos.map((producto) => (
            <Grid
              item
              key={producto.id ? producto.id : 1}
              md={4}
              sm={6}
              xs={12}
            >
              <Card className={classes.card}>
                <CardMedia className={classes.impre} ref={componentRef}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Typography color='primary' variant='body1'>
                      BIN NO: {producto.ubi}
                    </Typography>
                    <Typography color='primary' variant='body2'>
                      1 PC
                    </Typography>
                  </Box>
                  <Divider className={classes.divi} />
                  <Box>
                    <TextField
                      autoComplete='off'
                      color= 'secondary'
                      fullWidth
                      id={producto.id}
                      inputProps={{
                        style: {
                          textTransform: 'uppercase',
                          textAlign: 'center',
                          color: '#070d0d'
                        }
                      }}
                      onChange={handleChangeSerie}
                      value={serie}
                    />
                  </Box>
                  <Box
                    alignItems='center'
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-around'
                  >
                    <Box
                      alignItems='center'
                      display='flex'
                      flexDirection='column'
                    >
                      <Typography
                        color='primary'
                        style={{ fontWeight: '800', fontSize: '25px' }}
                      >
                        {producto.parte}
                      </Typography>
                      <Typography color='primary' variant='body2'>
                        {hoy}
                      </Typography>
                    </Box>
                    <Box className={classes.bordeB}>
                      <QRCode size={100} value={serie} />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <InputBase
                    className={classes.printInput}
                      defaultValue='SAMSUNG'
                      inputProps={{
                        style: {
                          textTransform: 'uppercase',
                          textAlign: 'left'
                        }
                      }}
                    />
                    <InputBase
                      className={classes.printInput}
                      inputProps={{
                        style: {
                          textTransform: 'uppercase',
                          textAlign: 'right'
                        }
                      }}
                      onChange={handleChangeOrigen}
                      value={origen}
                    />
                  </Box>
                  <Divider className={classes.divi2} />
                </CardMedia>
                <CardActions className={classes.botonEspaciso}>
                  <ReactToPrint
                    content={() => componentRef.current}
                    trigger={() => (
                      <Button
                        className={classes.containedSizeLarge}
                        color='secondary'
                        variant='contained'
                      >
                        Imprimir
                      </Button>
                    )}
                  />
                  <Button
                    className={classes.containedSizeLarge}
                    color='secondary'
                    onClick={() => onClickProductoDetalle(producto.id)}
                    variant='contained'
                  >
                    Ver
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      {tipoDialogo === 'productoNuevo' && (
        <DlgProductoNuevo parte={campoBusqueda} titulo='Parte Nueva CE' />
      )}
      {tipoDialogo === 'productoDetalle' && (
        <DlgProductoDetalle titulo='Detalle del producto' />
      )}
    </Container>
  );
};

ProductosCE.propTypes = {
  productos: PropTypes.array.isRequired
};

export default ProductosCE;
