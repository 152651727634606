import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ContactoLogo from '../../recursos/contacto.png';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
/*     position: 'initial',
    [theme.breakpoints.up('md')]: {
      position: 'fixed'
    } */
    /* NOTA: opcional ver si la info se mantiene fixed en el lado derecho si no es mobile xs up */
    /* position: 'fixed' */
  }
}));

const Info = () => {
  const classes = useStyles();
  return (
    <Grid item md={3} sm={12} xs={12}>
      <Paper className={classes.paper}>
        <Typography variant='h6'>CONTÁCTANOS »»»»»»»»»»</Typography>
        <Hidden xsDown>
          <Link className={classes.links} component={RouterLink} to='/contacto'>
            <img alt='samsung' className={classes.logo} src={ContactoLogo} />
          </Link>
        </Hidden>
        <Typography>SAMSUNG</Typography>
        <Typography paragraph>
          Dirección: Av. De la Republica E5-45 y Alemania Teléfonos:
          1700-433-433 línea a nivel nacional / 02-2241646 / 02-2922543 Celular:
          0992012378 / 0991315887 Correos: ventas1@idfimportadora.com /
          ventas2@idfimportadora.com Sugerencias y Comentarios:
          info@idfimportadora.com QUITO - ECUADOR
        </Typography>
        <Typography paragraph>
          MAPA
        </Typography>
      </Paper>
    </Grid>
  );
};

export default Info;
