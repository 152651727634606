import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { Box, Container, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import GuiasEstadisticas from '../charts/guiasEstadisticas';
import PiezasEstadisticas from '../charts/piezasEstadisticas';
import TalleresEstadisticas from '../charts/talleresEstadisticas';
import DestinosEstadisticas from '../charts/destinosEstadisticas';
import CouriersEstadisticas from '../charts/couriersEstadisticas';

import CouriersListaIngreso from '../couriers/courirersListaIngreso';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary
  }
}));

const PanelControlEstadisticas = () => {
  const classes = useStyles();

  return (
    <>
      <Box style={{ minHeight: '100%' }}>
        <Container maxWidth={false}>
          <Grid container spacing={3}>
            <Grid item lg={3} md={4} sm={6} xl={3} xs={12}>
              <GuiasEstadisticas />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xl={3} xs={12}>
              <PiezasEstadisticas />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xl={3} xs={12}>
              <TalleresEstadisticas />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xl={3} xs={12}>
              <DestinosEstadisticas />
            </Grid>

            <Grid item lg={8} md={12} xl={9} xs={12}>
              <Paper className={classes.paper} style={{ height: '100%' }}>
                <Typography align='center' variant='h5'>
                  Últimas Guias DHL
                </Typography>
                <CouriersListaIngreso />
              </Paper>
            </Grid>
            <Grid item lg={4} md={6} xl={3} xs={12}>
              <CouriersEstadisticas />
            </Grid>

            <Grid item lg={4} md={6} style={{ display: 'none' }} xl={3} xs={12}>
              <Paper className={classes.paper} style={{ height: '100%' }}>
                <Typography align='center' variant='h5'>
                  Últimas Guias Nacionales
                </Typography>
                <Typography align='center' paragraph variant='body2'>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi repudiandae et hic
                  totam inventore perspiciatis voluptatum. Temporibus officiis rem debitis,
                  veritatis praesentium fugiat nihil similique vel laudantium harum repellat ea!
                  Laborum error molestias nihil eum sint corporis nam voluptate harum hic
                  consequuntur, optio ipsum illo, eius atque exercitationem accusamus pariatur quia
                </Typography>
              </Paper>
            </Grid>
            <Grid item lg={8} md={12} style={{ display: 'none' }} xl={9} xs={12}>
              <Paper className={classes.paper} style={{ height: '100%' }}>
                <Typography align='center' variant='h5'>
                  Últimas Guias DHL
                </Typography>
                <Typography align='center' paragraph variant='body2'>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Modi repudiandae et hic
                  totam inventore perspiciatis voluptatum. Temporibus officiis rem debitis,
                  veritatis praesentium fugiat nihil similique vel laudantium harum repellat ea!
                  Laborum error molestias nihil eum sint corporis nam voluptate harum hic
                  consequuntur, optio ipsum illo, eius atque exercitationem accusamus pariatur quia
                  magnam repellendus reiciendis fuga aliquid dolorum ipsam. Nisi, repellendus. Odio
                  soluta sed est? Voluptate dignissimos, nobis voluptates laborum ipsa saepe
                  repellendus pariatur ipsum aperiam fugiat beatae molestias veniam atque totam quos
                  mollitia praesentium iure repellat possimus quia libero minus.
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default PanelControlEstadisticas;
