import React, { useState } from 'react';
import { _cmdaddGuiaDHL } from '../../base/acciones/courier.Acciones';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Box,
  Container,
  Grid,
  Avatar,
  Card,
  CardContent,
  Typography,
  TextField
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import Brightness1Icon from '@material-ui/icons/Brightness1';

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
});

const transportistas = ['LAAR', 'SERVIENTREGA', 'TRAMACO', 'DHL'];
const CouriersIngreso = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const hoy = new Date().toLocaleDateString();
  // const [transportista, setTransportista] = React.useState(transportistas[0]);

  const [ingresoGuias, setIngresoGuias] = useState({
    fecha: hoy,
    transportista: transportistas[0],
    guia: 0,
    piezas: 1,
    novedades: 'N/A',
    estado: false,
    responsable: ''
  });

  const cmdAddGuiaDhl = (valores) => {
    /*     const valoresFinales = { ...values, ...valores }; */
    dispatch(_cmdaddGuiaDHL(valores));
  };
  // const onSubmit = (data) => console.log(data);

  const handleChangeGuia = (event) => {
    // setOrigen(event.target.value);
    console.log(event.target.value);
  };
  return (
    <>
      <h3>Desarrollo del ingreso de guias DHL mediante un scaner o lector. {hoy} </h3>
      <button
        onClick={() =>
          dispatch(
            _cmdaddGuiaDHL({
              guia: 1554654138798,
              piezas: 10,
              observaciones: 'Se recibe la caja rota',
              fecha: '20/07/2021'
            })
          )
        }
      >
        Añadir GUIAS
      </button>
      <Container maxWidth={false}>
        <Grid container>
          <Grid item lg={12} md={12} sm={12} xl={12} xs={12}>
            <Card>
              <CardContent>
                <Box
                  alignItems='center'
                  color='primary.main'
                  display='flex'
                  justifyContent='space-between'
                  m={1}
                >
                  <Typography color='primary' gutterBottom variant='h6'>
                    Guias del día {ingresoGuias.fecha}
                  </Typography>
                 
                  <Avatar style={{ backgroundColor: '#070D0D', height: '56px', width: '56px' }}>
                    <CreateOutlinedIcon />
                  </Avatar>
                </Box>
                <Divider />
                <Box
                  alignItems='center'
                  color='primary.main'
                  display='flex'
                  justifyContent='space-between'
                  m={1}
                >
                  <Typography color='primary' variant='h6'>
                    Total Piezas: {ingresoGuias.piezas}
                  </Typography>
                  {!ingresoGuias.estado ? (
                    <Brightness1Icon fontSize='default' style={{ color: 'red' }} />
                  ) : (
                    <Brightness1Icon fontSize='small' style={{ color: '#28ef04' }} />
                  )}
                </Box>
                <Divider />
                <Box alignItems='center' display='flex' justifyContent='space-between' m={4}>
                  <TextField
                    InputProps={{ inputProps: { min: 0 } }}
                    label='Número de guia'
                    margin='normal'
                    name='guia'
                    onChange={handleChangeGuia}
                    required={true}
                    style={{ width: '25ch' }}
                    type='number'
                    variant='outlined'
                  />
                  <ArrowRightOutlinedIcon fontSize='large' style={{ color: 'green' }} />
                  <TextField
                    defaultValue='N/A'
                    id='outlined-multiline-static'
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    label='Novedades'
                    multiline
                    rows={2}
                    variant='outlined'
                  />
                </Box>
                <Divider />
                <TableContainer component={Paper}>
                  <Table aria-label='a dense table' className={classes.table} size='small'>
                    <TableHead>
                      <TableRow>
                        <TableCell># Guia</TableCell>
                        <TableCell align='right'>Piezas</TableCell>
                        <TableCell align='right'>Observación</TableCell>
                        <TableCell align='right'>Estado</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/*                       {rows.map((row) => ( */}
                      <TableRow key={'row.name'}>
                        <TableCell
                          component='th'
                          scope='row'
                          style={{ fontWeight: 600, fontSize: '1.5em' }}
                        >
                          8456484125
                        </TableCell>
                        <TableCell align='right' style={{ color: 'red' }}>
                          5/10
                        </TableCell>
                        <TableCell align='right'>Caja Semi Abierta</TableCell>
                        <TableCell align='right' style={{ color: 'red' }}>
                          Incompleto
                        </TableCell>
                        <TableCell align='right' style={{ color: 'red' }}>
                          <button
                            onClick={
                              {
                                /* () => focusText(item.id, item.name) */
                              }
                            }
                          >
                            Eliminar
                          </button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CouriersIngreso;
