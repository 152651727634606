import { MOSTRAR_BUSCADOR, RESET_BUSCADOR } from '../acciones/constantes';

const initialState = {
  abiertoBuscador: true
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MOSTRAR_BUSCADOR:
      return {
        ...state,
        abiertoBuscador: !state.abiertoBuscador
      };
    case RESET_BUSCADOR:
      return {
        ...state,
        abiertoBuscador: false
      };

    default:
      return state;
  }
};
