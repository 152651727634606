import { push } from 'connected-react-router';
import { CAMBIAR_TEMA, APP_SOLICITA_CAMBIAR_TEMA } from './constantes';

export const _cmdcambiarTema = () => {
  return (dispatch, getState) => {
    const esTema = !getState().tema.tema;
    dispatch({
      type: APP_SOLICITA_CAMBIAR_TEMA
    });
    setTimeout(() => {
      dispatch({
        type: APP_SOLICITA_CAMBIAR_TEMA
      });
      dispatch({
        type: CAMBIAR_TEMA
      });
    }, 500);

    localStorage.setItem('tema', esTema);
  };
};
export const _cmdcargarLoader = () => {
  return (dispatch, getState) => {
    setTimeout(() => {
      dispatch({
        type: APP_SOLICITA_CAMBIAR_TEMA
      });
    }, 500);
    dispatch({
      type: APP_SOLICITA_CAMBIAR_TEMA
    });
  };
};
export const _cmdredirigiInicio = () => {
  return (dispatch, getState) => {
    dispatch(push('/'));
  };
};
