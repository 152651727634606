import React from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { _cmdresetDialogo } from '../../base/acciones/dialogo.Acciones';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import { useForm, Controller } from 'react-hook-form';
import { _cmdpostProductoNuevo } from '../../base/acciones/inventario.Acciones';
import _ from 'lodash';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const ProductoNuevo = ({ parte, titulo }) => {
  const methods = useForm();
  const { handleSubmit, control } = methods;
  const dispatch = useDispatch();
  const abiertoDialogo = useSelector((state) => state.dialogo.abiertoDialogo);

  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  // const onSubmit = (data) => console.log(data);

  const onClickProductoNuevo = (data) => {
    const obj = _.mapValues(data, (v, k) => {
      return v.toUpperCase();
    });

    dispatch(_cmdresetDialogo());
    setTimeout(() => {
      dispatch(_cmdpostProductoNuevo(obj));
    }, 500);
  };

  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      fullWidth
      maxWidth='md'
      onClose={() => dispatch(_cmdresetDialogo())}
      open={abiertoDialogo}
      TransitionComponent={Transition}
    >
      <DialogTitle id='alert-dialog-title'>{titulo}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          A continuación agregaras el siguiente número de parte: <br /> {parte}, por favor ingresa
          la descripción completa y la ubicación en bodega.
        </DialogContentText>
        <Box textAlign='justify'>
          <form autoComplete='off' noValidate>
            <Controller
              control={control}
              defaultValue={parte}
              name='parte'
              render={({ field }) => (
                <TextField
                  {...field}
                  disabled
                  fullWidth
                  label='Nro. de Parte'
                  margin='normal'
                  name='parte'
                  required={true}
                  variant='outlined'
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='descripcion'
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  fullWidth
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                  label='Descripción'
                  margin='normal'
                  name='des'
                  required={true}
                  variant='outlined'
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=''
              name='ubi'
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  inputProps={{ style: { textTransform: 'uppercase' } }}
                  label='Ubicación'
                  margin='normal'
                  name='ubi'
                  required={true}
                  variant='outlined'
                />
              )}
            />
          </form>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color='secondary' onClick={handleSubmit(onClickProductoNuevo)} variant='contained'>
          Aceptar
        </Button>
        <Button color='primary' onClick={() => dispatch(_cmdresetDialogo())} variant='contained'>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProductoNuevo.propTypes = {
  titulo: PropTypes.string.isRequired,
  parte: PropTypes.string.isRequired
};

export default ProductoNuevo;
