import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Couriers from '../../componentes/couriers';
import Titulos from '../../componentes/titulos';
import { _cmdcargarLoader } from '../../base/acciones/tema.Acciones';
import Cargador from '../../componentes/cargador';

const CouriersPagina = () => {

  const estaCargando = useSelector((state) => state.tema.temaCargando);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(_cmdcargarLoader());
  }, [dispatch]);

  return (
    <>          
      <Titulos titulo='Couriers' />
      <Cargador estaCargando={estaCargando} />
      <Couriers />
    </>
  );
};
export default CouriersPagina;
