import React, { useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardActions from '@material-ui/core/CardActions';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import JsBarcode from 'react-barcode';
import QRCode from 'qrcode.react';
import ReactToPrint from 'react-to-print';
import DlgProductoNuevo from './productoNuevo';
import DlgProductoDetalle from './productoDetalle';
import { useSelector, useDispatch } from 'react-redux';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';
import { _cmdgetProductosbyID } from '../../base/acciones/inventario.Acciones';
import Divider from '@material-ui/core/Divider';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles((theme) => ({
  botonEspacio: {
    justifyContent: 'flex-end'
  },
  cardGrid: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(8)
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  impre: {
    margin: '4%',
    fontWeight: 600 // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  centrar: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '80vh'
  },
  bordeB: {
    marginTop: '5px'
  },
  containedSizeLarge: {
    padding: '40px 90px'
  },
  divi: {
    backgroundColor: '#070d0d',
    height: '2px',
    '@media print': {
      overflowY: 'visible',
      boxShadow: 'none'
    }
  },
  divi2: {
    backgroundColor: '#070d0d',
    '@media print': {
      overflowY: 'visible',
      boxShadow: 'none'
    }
  },
  printInput: {
    '@media print': {
      color: '#070d0d'
    }
  }
}));

const Productos = ({ productos }) => {
  const [bodega, setBodega] = useState('HHP');
  const dispatch = useDispatch();
  const tipoDialogo = useSelector((state) => state.dialogo.tipo);
  const campoBusqueda = useSelector((state) => state.inventario.campoBusqueda);
  const classes = useStyles();
  const componentRef = useRef();
  const hoy = new Date().toLocaleDateString();

  const onClickProductoDetalle = (id) => {
    dispatch(_cmdgetProductosbyID(id));
    setTimeout(() => {
      dispatch(_cmdmostrarDialogo('productoDetalle'));
    }, 500);
  };
  const handleChangeBodega = (event) => {
    setBodega(event.target.value);
  };

  return (
    <Container className={classes.cardGrid} component='main' maxWidth='lg'>
      <Grid container spacing={4}>
        {productos.length === 0 && (
          <Typography
            align='center'
            className={classes.centrar}
            color='error'
            component='div'
            variant='h3'
          >
            Error al conectarse con la API remota. Por favor contacte con un
            administrador.
          </Typography>
        )}
        {productos &&
          productos.map((producto) => (
            <Grid
              item
              key={producto.id ? producto.id : 1}
              md={4}
              sm={6}
              xs={12}
            >
              <Card className={classes.card}>
                <CardMedia className={classes.impre} ref={componentRef}>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Typography variant='body1'>
                      {'BIN NO: '}
                      <span style={{ fontWeight: 600, fontSize: '1em' }}>{producto.ubi}</span>
                    </Typography>
                    <Typography variant='body2'>1 PC</Typography>
                  </Box>
                  <Divider className={classes.divi} />
                  <Box>
                    <Typography
                      align='left'
                      color='primary'
                      noWrap
                      variant='subtitle2'
                    >
                      {producto.descripcion}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Box className={classes.bordeB}>
                      <QRCode size={80} value={producto.parte} />
                    </Box>
                    <Box>
                      <JsBarcode
                        background={'#FFFFFF'}
                        fontface={'Roboto'}
                        fontSize={40}
                        format={'CODE128'}
                        height={20}
                        value={producto.parte}
                        width={1.5}
                      />
                    </Box>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Typography color='primary' variant='body2'>
                      SAMSUNG
                    </Typography>
                    <Typography color='primary' variant='body2'>
                      KOREA
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                  >
                    <Box>
                      <InputBase
                        inputProps={{
                          style: {
                            textTransform: 'uppercase',
                            textAlign: 'left',
                            fontSize: '1.125rem',
                            padding: 0
                          }
                        }}
                        margin='none'
                        onChange={handleChangeBodega}
                        value={bodega}
                      />
                    </Box>
                    <Box>
                      <Typography color='primary' variant='body2'>
                        {hoy}
                      </Typography>
                    </Box>
                  </Box>
                  <Divider className={classes.divi2} />
                </CardMedia>
                <CardActions className={classes.botonEspaciso}>
                  <ReactToPrint
                    content={() => componentRef.current}
                    trigger={() => (
                      <Button
                        className={classes.containedSizeLarge}
                        color='secondary'
                        variant='contained'
                      >
                        Imprimir
                      </Button>
                    )}
                  />
                  <Button
                    className={classes.containedSizeLarge}
                    color='secondary'
                    onClick={() => onClickProductoDetalle(producto.id)}
                    variant='contained'
                  >
                    Ver
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
      {tipoDialogo === 'productoNuevo' && (
        <DlgProductoNuevo parte={campoBusqueda} titulo='Parte Nueva HHP' />
      )}
      {tipoDialogo === 'productoDetalle' && (
        <DlgProductoDetalle titulo='Detalle del producto' />
      )}
    </Container>
  );
};

Productos.propTypes = {
  productos: PropTypes.array.isRequired
};

export default Productos;
