import { APP_SOLICITA, RESET_DIALOGO } from './constantes';
import { toastr } from 'react-redux-toastr';

export const _cmdRegistroUsuario = (datos) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    const firebase = getFirebase();
    dispatch({
      type: RESET_DIALOGO
    });
    dispatch({
      type: APP_SOLICITA
    });
    setTimeout(() => {
      firebase
        .createUser(
          { email: datos.email, password: datos.password },
          { displayName: datos.nombre, email: datos.email, avatarUrl: '', isAdmin: false }
        )
        .then(() => {
          dispatch({
            type: APP_SOLICITA
          });
          toastr.success(
            'Bienvenido',
            'Registro satisfactorio.'
          );
        })
        .catch((error) => {
          console.table(error);
          dispatch({
            type: APP_SOLICITA
          });
          toastr.error(
            'Error',
            error
          );
        });
    }, 500);
    // localStorage.setItem('idioma', idioma);
  };
};

export const _cmdIngresarUsuario = (datos) => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    const firebase = getFirebase();
    dispatch({
      type: RESET_DIALOGO
    });
    dispatch({
      type: APP_SOLICITA
    });
    setTimeout(() => {
      firebase
        .login({
          email: datos.email,
          password: datos.password
        })
        .then(() => {
          dispatch({
            type: APP_SOLICITA
          });
          toastr.success(
            'Bienvenido',
            'Ingreso al sistema satisfactoriamente.'
          );
        })
        .catch(() => {
          dispatch({
            type: APP_SOLICITA
          });
          toastr.error(
            'Error',
            'Usuario/Contraseña incorrectos. Por favor intentelo de nuevo.'
          );
        });
    }, 500);
    // localStorage.setItem('idioma', idioma);
  };
};

export const _cmdIngresarGoogle = () => {
  return (dispatch, getState, { getFirebase, axiosInstance }) => {
    const firebase = getFirebase();
    dispatch({
      type: RESET_DIALOGO
    });
    dispatch({
      type: APP_SOLICITA
    });
    setTimeout(() => {
      firebase
        .login({
          provider: 'google',
          type: 'popup'
        })
        .then(() => {
          dispatch({
            type: APP_SOLICITA
          });
          toastr.success(
            'Bienvenido',
            'Ingreso al sistema satisfactoriamente.'
          );
        })
        .catch((error) => {
          dispatch({
            type: APP_SOLICITA
          });
          toastr.error('Error', 'Por favor intentelo de nuevo.');
        });
    }, 500);
    // localStorage.setItem('idioma', idioma);
  };
};
