const secretosMain = () => ({
  /*   apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATA_BASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGE_SENDER,
  appId: process.env.REACT_APP_APP_ID */
  apiKey: 'AIzaSyCfhQXHZAJB3gRHBiaVCPTE12d9xQzWFLA',
  authDomain: 'assybarras19.firebaseapp.com',
  databaseURL: 'https://assybarras19.firebaseio.com',
  projectId: 'assybarras19',
  storageBucket: 'assybarras19.appspot.com',
  messagingSenderId: '763407288785',
  appId: '1:763407288785:web:f5911c960586f332'
});

export default secretosMain;
