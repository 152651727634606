import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink, useRouteMatch } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { _cmdcambiarTema } from '../../base/acciones/tema.Acciones';
import { _cmdcambiarLenguaje } from '../../base/acciones/idioma.Acciones';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Brightness4Icon from '@material-ui/icons/Brightness4';
import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import ThumbUpOutlinedIcon from '@material-ui/icons/ThumbUpOutlined';
import PhoneCallbackIcon from '@material-ui/icons/PhoneCallback';
import Typography from '@material-ui/core/Typography';
import ec from '../../estaticos/ec.png';
import us from '../../estaticos/us.png';
import { useIntl } from 'react-intl';
import Hidden from '@material-ui/core/Hidden';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';
import DialogoIngreso from '../dialogoIngreso2';
import DialogoRegistro from '../dialogoRegistro';
import Avatar from '../avatar';
import SearchIcon from '@material-ui/icons/Search';
import TvIcon from '@material-ui/icons/Tv';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import SyncIcon from '@material-ui/icons/Sync';
import HomeIcon from '@material-ui/icons/Home';
import { _cmdmostrarBuscador } from '../../base/acciones/buscador.Acciones';
import Slide from '@material-ui/core/Slide';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import { push } from 'connected-react-router';

const useStyles = makeStyles((theme) => ({
  Iconos: {
    padding: theme.spacing(0, 1)
  },
  corte: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block'
    }
  },
  corte2: {
    justifyContent: 'space-between',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'space-between'
    }
  },
  aire: {
    margin: theme.spacing(1),
    height: '4em',
    '&:hover': {
      textDecoration: 'noone'
    }
  },
  idioma: {
    margin: theme.spacing(1),
    fontWeight: 600,
    letterSpacing: '3px',
    padding: '0 5px'
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  logo: {
    margin: theme.spacing(0.5),
    maxHeight: 20,
    display: 'block'
  },
  borde: {
    border: '1px solid #FDDC5C',
    borderRadius: '7px',
    padding: theme.spacing(0.5)
  }
}));

const HideOnScroll = (props) => {
  const { children } = props;

  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
};

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired
};

const Superior = ({ propColor, propPosicion }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const auth = useSelector((state) => state.firebase.auth);
  const tema = useSelector((state) => state.tema.tema);
  const tipoDialogo = useSelector((state) => state.dialogo.tipo);
  // const estaCargando = useSelector((state) => state.lenguaje.idiomaCargando);
  const displayName = useSelector((state) => state.firebase.profile.displayName);

  let { path } = useRouteMatch();

  return (
    <div className={classes.root2}>
      <HideOnScroll>
        <AppBar color={propColor} position={propPosicion}>
          {isEmpty(auth) ? (
            <Toolbar style={{ justifyContent: 'space-between' }}>
              <div style={{ display: 'flex' }}>
                <Hidden smDown>
                  {intl.locale === 'es' ? (
                    <Button
                      className={classes.idioma}
                      color='primary'
                      onClick={() => {
                        dispatch(_cmdcambiarLenguaje('en'));
                      }}
                      size='small'
                      startIcon={<img alt='logo' className={classes.logo} src={us} />}
                      variant='contained'
                    >
                      {intl.formatMessage({
                        id: 'superior.en',
                        defaultMessage: 'English'
                      })}
                    </Button>
                  ) : (
                    <Button
                      className={classes.idioma}
                      color='primary'
                      onClick={() => {
                        dispatch(_cmdcambiarLenguaje('es'));
                      }}
                      size='small'
                      startIcon={<img alt='logo' className={classes.logo} src={ec} />}
                      variant='contained'
                    >
                      {intl.formatMessage({
                        id: 'superior.es',
                        defaultMessage: 'Español'
                      })}
                    </Button>
                  )}
                  <div style={{ display: 'flex' }}>
                    <IconButton aria-label='contacto' color='secondary' size='small'>
                      <PhoneCallbackIcon />
                    </IconButton>
                    <Typography style={{ fontWeight: '600', marginLeft: '10px' }} variant='caption'>
                      <span>
                        <span aria-label='Visto' role='img'>
                          ✔️{' '}
                        </span>
                        {intl.formatMessage({
                          id: 'superior.ventas',
                          defaultMessage: 'CONTACTOS'
                        })}
                      </span>
                      <br />
                      <span>
                        <span aria-label='Celular' role='img'>
                          📲{' '}
                        </span>
                        <Link
                          color='textPrimary'
                          href='https://wa.me/593986975303?text=Me%20gustaría%20saber%20más%20sobre%20las%20APLICACIONES%20WEB%20que%20desarrollas%20actualmente.%20||%20I%20would%20like%20to%20know%20more%20about%20the%20WEB%20APPLICATIONS%20that%20you%20currently%20develop.%20'
                          rel='noopener'
                          target='_blank'
                        >
                          +593 986 975 303
                        </Link>
                        <span aria-label='Celular' role='img'>
                          {' '}
                          📧{' '}
                        </span>
                        <Link
                          color='textPrimary'
                          href='&#0109;&#97;&#105;&#108;&#116;&#111;&#58;&#102;i&#00122;&#122;&#118;&#114;&#0064;&#103;&#00109;&#97;&#00105;&#00108;&#0046;&#0099;&#00111;&#109;'
                          rel='noopener'
                          target='_blank'
                        >
                          &#102;i&#00122;&#122;&#118;&#00114;&#64;&#00103;&#00109;&#0097;&#105;&#108;&#46;&#99;&#111;&#00109;
                        </Link>
                      </span>
                    </Typography>
                  </div>
                </Hidden>
              </div>
              <div style={{ display: 'flex' }}>
                <Button
                  className={classes.aire}
                  color='secondary'
                  onClick={() => dispatch(push('/accesorios'))}
                  size='small'
                  startIcon={<ThumbUpOutlinedIcon />}
                  variant='outlined'
                >
                  {intl.formatMessage({
                    id: 'superior.accesorios',
                    defaultMessage: 'accesorios'
                  })}
                </Button>
                <Button
                  className={classes.aire}
                  color='secondary'
                  onClick={() => dispatch(_cmdmostrarDialogo('registro'))}
                  size='small'
                  startIcon={<ThumbUpOutlinedIcon />}
                  variant='outlined'
                >
                  {intl.formatMessage({
                    id: 'superior.registro',
                    defaultMessage: 'registrarme'
                  })}
                </Button>
                <Button
                  className={classes.aire}
                  color='secondary'
                  onClick={() => dispatch(_cmdmostrarDialogo('ingreso'))}
                  size='small'
                  startIcon={<TouchAppIcon />}
                  variant='contained'
                >
                  {intl.formatMessage({
                    id: 'superior.ingreso',
                    defaultMessage: 'iniciar sesión'
                  })}
                </Button>
                <Tooltip title={`${tema ? 'Tema Obscuro' : 'Tema Claro'}`}>
                  <IconButton
                    aria-label='tema'
                    color='secondary'
                    onClick={() => dispatch(_cmdcambiarTema())}
                  >
                    {tema ? <Brightness4Icon /> : <BrightnessHighIcon />}
                  </IconButton>
                </Tooltip>
              </div>
            </Toolbar>
          ) : (
            <Toolbar className={classes.corte2}>
              <div className={classes.corte1}>
                <Button
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/'))}
                  size='small'
                  startIcon={
                    <Tooltip title='Inicio'>
                      <IconButton
                        aria-label='inicio'
                        className={classes.Iconos}
                        color='secondary'
                        disableFocusRipple
                        disableRipple
                      >
                        <HomeIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  variant='text'
                >
                  <div className={classes.corte}>Inicio</div>
                </Button>
                <Button
                  className={classes.button}
                  color='secondary'
                  onClick={() => dispatch(push('/dashboard'))}
                  startIcon={<PersonPinIcon />}
                  variant='contained'
                >
                  Panel de Control
                </Button>
                <Button
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/hhp'))}
                  size='small'
                  startIcon={
                    <Tooltip title='Hand-Help Phone'>
                      <IconButton
                        aria-label='Hand-Help Phone'
                        className={classes.Iconos}
                        color='secondary'
                        disableFocusRipple
                        disableRipple
                      >
                        <PhoneAndroidIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  variant='text'
                >
                  <div className={classes.corte}>HHP</div>
                </Button>
                <Button
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/ce'))}
                  size='small'
                  startIcon={
                    <Tooltip title='Customer Electronics'>
                      <IconButton
                        aria-label='Customer Electronics'
                        className={classes.Iconos}
                        color='secondary'
                        disableFocusRipple
                        disableRipple
                      >
                        <TvIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  variant='text'
                >
                  <div className={classes.corte}>CE</div>
                </Button>
                <Button
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/recoleccion'))}
                  size='small'
                  startIcon={
                    <Tooltip title='Screen Collection'>
                      <IconButton
                        aria-label='screen collection'
                        className={classes.Iconos}
                        color='secondary'
                        disableFocusRipple
                        disableRipple
                      >
                        <SyncIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  variant='text'
                >
                  <div className={classes.corte}>Recolecciones</div>
                </Button>
                <Button
                  color='secondary'
                  onClick={() => dispatch(push('/tablero/couriers'))}
                  size='small'
                  startIcon={
                    <Tooltip title='Couriers'>
                      <IconButton
                        aria-label='Couriers'
                        className={classes.Iconos}
                        color='secondary'
                        disableFocusRipple
                        disableRipple
                      >
                        <AirportShuttleIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  variant='text'
                >
                  <div className={classes.corte}>Couriers</div>
                </Button>
                <Button
                  color='secondary'
                  onClick={() => dispatch(_cmdmostrarBuscador())}
                  size='small'
                  startIcon={
                    <Tooltip title='Buscador'>
                      <IconButton
                        aria-label='Buscador'
                        className={classes.Iconos}
                        color='secondary'
                        disableFocusRipple
                        disableRipple
                      >
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>
                  }
                  variant='text'
                >
                  <div className={classes.corte}>Buscador</div>
                </Button>
              </div>
              <div className={classes.root}>
                <div>Bienvenido, {displayName}</div>
                <Avatar />
              </div>
            </Toolbar>
          )}
        </AppBar>
      </HideOnScroll>
      <Toolbar />
      {tipoDialogo === 'ingreso' && <DialogoIngreso titulo='Iniciar sesión' />}
      {tipoDialogo === 'registro' && <DialogoRegistro titulo='Inscríbete' />}
    </div>
  );
};

Superior.propTypes = {
  propColor: PropTypes.string,
  propPosicion: PropTypes.string
};

export default Superior;
