import React from 'react';
import Box from '@material-ui/core/Box';
import { useDispatch, useSelector } from 'react-redux';
import { _cmdresetDialogo } from '../../base/acciones/dialogo.Acciones';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { _cmdputProductosbyID } from '../../base/acciones/inventario.Acciones';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const ProductoDetalle = ({ titulo }) => {
  const [state, setState] = React.useState({
    chkModificar: false
  });
  const classes = useStyles();
  const methods = useForm();
  const { handleSubmit, control } = methods;
  const dispatch = useDispatch();
  const abiertoDialogo = useSelector((state) => state.dialogo.abiertoDialogo);
  // const campoBusqueda = useSelector((state) => state.inventario.campoBusqueda);
  const catProductoFiltrado = useSelector((state) => state.inventario.catProductoFiltrado);

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  /* const onSubmit = (data) => console.log(data); */

  const onClickProductoModificar = (data) => {
    const obj = _.mapValues(data, (v, k) => {
      return v.toUpperCase();
    });
    dispatch(_cmdresetDialogo());
    setTimeout(() => {
      dispatch(_cmdputProductosbyID(obj));
    }, 500);
  };

  return (
    <Dialog
      aria-describedby='alert-dialog-description'
      aria-labelledby='alert-dialog-title'
      fullWidth
      maxWidth='md'
      onClose={() => dispatch(_cmdresetDialogo())}
      open={abiertoDialogo}
      TransitionComponent={Transition}
    >
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' flexDirection='row' justifyContent='space-between'>
          <Box>{titulo}</Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.chkModificar}
                  name='chkModificar'
                  onChange={handleChange}
                />
              }
              label='Modificar'
            />
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Box textAlign='justify'>
          {catProductoFiltrado.map((producto) => (
            <form autoComplete='off' key={producto.id ? producto.id : 1} noValidate>
              <Controller
                control={control}
                defaultValue={producto.parte}
                name='parte'
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled
                    fullWidth
                    label='Nro. de Parte'
                    margin='normal'
                    name='parte'
                    required={true}
                    variant='outlined'
                  />
                )}
              />
              <Controller
                control={control}
                defaultValue={producto.descripcion}
                name='descripcion'
                render={({ field }) => (
                  <TextField
                    {...field}
                    disabled={!state.chkModificar}
                    fullWidth
                    inputProps={{ style: { textTransform: 'uppercase' } }}
                    label='Descripción'
                    margin='normal'
                    name='des'
                    required={true}
                    variant='outlined'
                  />
                )}
              />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Controller
                  control={control}
                  defaultValue={producto.ubi}
                  name='ubi'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!state.chkModificar}
                      inputProps={{ style: { textTransform: 'uppercase' } }}
                      label='Ubicación'
                      margin='normal'
                      name='ubi'
                      required={true}
                      variant='outlined'
                    />
                  )}
                />
                <Controller
                  control={control}
                  defaultValue={producto.esAccesorio > 0 ? 'SI' : 'NO'}
                  name='accesorio'
                  render={({ field }) => (
                    <TextField
                      {...field}
                      disabled={!state.chkModificar}
                      label='Es accesorio'
                      margin='normal'
                      name='accesorio'
                      required={true}
                      variant='outlined'
                    />
                  )}
                />
              </div>
            </form>
          ))}
        </Box>
      </DialogContent>
      <DialogActions
        disableSpacing
        style={{
          justifyContent: 'space-between'
        }}
      >
        <Button
          color='primary'
          disabled={!state.chkModificar}
          onClick={handleSubmit(onClickProductoModificar)}
          size='small'
          variant='outlined'
        >
          Eliminar
        </Button>
        <Box className={classes.root}>
          <Button
            color='secondary'
            disabled={!state.chkModificar}
            onClick={handleSubmit(onClickProductoModificar)}
            variant='contained'
          >
            Aceptar
          </Button>
          <Button color='primary' onClick={() => dispatch(_cmdresetDialogo())} variant='contained'>
            Cancelar
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

ProductoDetalle.propTypes = {
  titulo: PropTypes.string.isRequired
};

export default ProductoDetalle;
