import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Avatar, Card, CardContent, Grid, Typography } from '@material-ui/core';
import HomeWorkIcon from '@material-ui/icons/HomeWork';

const data = {
  labels: ['BIT', 'Elecservitec', 'Asitec', 'Electronica SA', 'Electronica Valverde'],
  datasets: [
    {
      label: '# de Guias',
      data: [250, 119, 30, 50, 20],
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)'
      ],
      borderColor: [
        'rgba(255, 99, 132, 1)',
        'rgba(54, 162, 235, 1)',
        'rgba(255, 206, 86, 1)',
        'rgba(75, 192, 192, 1)',
        'rgba(153, 102, 255, 1)'
      ],
      borderWidth: 1
    }
  ]
};

const options = {
  indexAxis: 'y',
  // Elements options apply to all of the options unless overridden in a dataset
  // In this case, we are setting the border of each horizontal bar to be 2px wide
  elements: {
    bar: {
      borderWidth: 2
    }
  },
  responsive: true,
  plugins: {
    legend: {
      position: 'top'
    },
    title: {
      display: false,
      text: 'Chart.js Horizontal Bar Chart'
    }
  }
};

const HorizontalBarChart = () => (
  <Card style={{ height: '100%' }}>
    <CardContent>
      <Grid container spacing={3} style={{ justifyContent: 'space-between' }}>
        <Grid item>
          <Typography color='textSecondary' gutterBottom variant='h6'>
            TOP TALLERES
          </Typography>
        </Grid>
        <Grid item>
          <Avatar style={{ backgroundColor: '#070D0D', height: '56px', width: '56px' }}>
            <HomeWorkIcon />
          </Avatar>
        </Grid>
        <Bar data={data} options={options} />
      </Grid>
    </CardContent>
  </Card>
);

export default HorizontalBarChart;
