// NOTA: 11 Rutas
import PanelControlEstadisticas from './panelControlEstadisticas';
import HppPaginaComponente from '../../contenedores/HhpPagina';
import CePaginaComponente from '../../contenedores/CePagina';
import SapPaginaComponente from '../../contenedores/SapPagina';
import RecoleccionesPaginaComponente from '../../contenedores/RecoPagina';
import TransportistasPaginaComponente from '../../contenedores/CouriersPagina';
import GuiasComponente from '../../componentes/couriers/couriersGuias';
import CouriersComponente from '../../componentes/couriers/couriersIngreso';
import TableroComponente from '../../contenedores/TableroPagina';

/* import SamsungPaginaComponente from './accesoriosSamsung';
import WhirpoolPaginaComponente from './accesoriosWhirpool';
import PanasonicPaginaComponente from './accesoriosPanasonic';
import SonyPaginaComponente from './accesoriosSony';
import OtrosPaginaComponente from './accesoriosOtros';
import PPPaginaComponente from './accesoriosPp';
import PromocionesPaginaComponente from './accesoriosPromociones';
import NosotrosPaginaComponente from './accesoriosNosotros';
import NoticiasPaginaComponente from './accesoriosNoticias';
import ContactoPaginaComponente from './accesoriosContacto'; */
// Iconos
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import HomeIcon from '@material-ui/icons/Home';


const rutasAccesorios = [
  {
    path: '/estadisticas',
    sidebarName: 'Estadisticas',
    navbarName: 'Estadisticas',
    icon: HomeIcon,
    component: PanelControlEstadisticas
  },
  {
    path: '/hhp',
    sidebarName: 'HHP',
    navbarName: 'HHP',
    icon: MailIcon,
    component: HppPaginaComponente
  },
  {
    path: '/ce',
    sidebarName: 'CE',
    navbarName: 'CE',
    icon: MailIcon,
    component: CePaginaComponente
  },
  {
    path: '/sap',
    sidebarName: 'SAP',
    navbarName: 'SAP',
    icon: MailIcon,
    component: SapPaginaComponente
  },
  {
    path: '/recolecciones',
    sidebarName: 'Recolecciones',
    navbarName: 'Recolecciones',
    icon: MailIcon,
    component: RecoleccionesPaginaComponente
  },
  {
    path: '/transportistas',
    sidebarName: 'Transportistas',
    navbarName: 'Transportistas',
    icon: MailIcon,
    component: GuiasComponente
  },
  {
    path: '/ingresos',
    sidebarName: 'Ingreso Guias',
    navbarName: 'Ingreso Guias',
    icon: MailIcon,
    component: CouriersComponente
  },
  {
    path: '/salidas',
    sidebarName: 'Salida Guias',
    navbarName: 'Salida Guias',
    icon: MailIcon,
    component: TransportistasPaginaComponente
  },
  {
    path: '/tablero',
    sidebarName: 'Tablero',
    navbarName: 'Tablero',
    icon: InboxIcon,
    component: TableroComponente
  }
];

export default rutasAccesorios;
