import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { Formik, Form, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import * as Yup from 'yup';
import { _cmdresetDialogo } from '../../base/acciones/dialogo.Acciones';
import {
  _cmdIngresarGoogle,
  _cmdIngresarUsuario
} from '../../base/acciones/ingreso.Acciones';
import Cargador from '../../componentes/cargador';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import Box from '@material-ui/core/Box';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import classNames from 'classnames';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import Link from '@material-ui/core/Link';
import { _cmdmostrarDialogo } from '../../base/acciones/dialogo.Acciones';

const useStyles = makeStyles((theme) => ({
  aire: {
    margin: theme.spacing(1)
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  apple: {
    background:
      'linear-gradient(to right, rgb(0, 0, 0) 2.6rem, transparent 2.6rem) rgb(238, 238, 238);'
  },
  google: {
    background:
      'linear-gradient(to right, rgb(66, 133, 244) 2.6rem, transparent 2.6rem) rgb(238, 238, 238)'
  },
  fb: {
    background:
      'linear-gradient(to right, rgb(59, 88, 152) 2.6rem, transparent 2.6rem) rgb(238, 238, 238)'
  },
  boton: {
    boxSizing: 'border-box',
    fontFamily: 'lato, Helvetica, Arial, sans-serif',
    fontSize: '1rem',
    color: 'rgb(68, 68, 68)',
    cursor: 'pointer',
    fontWeight: 700,
    lineHeight: 1.3,
    textAlign: 'center',
    paddingTop: 'calc(11px - 0.1ex)',
    paddingBottom: 'calc(11px + 0.1ex)',
    position: 'relative',
    paddingLeft: 'calc(2.6rem + 10px)',
    paddingRight: '10px',
    minWidth: '17rem',
    borderWidth: '0px',
    borderStyle: 'initial',
    borderColor: 'initial',
    borderImage: 'initial',
    borderRadius: '4px',
    textDecoration: 'none',
    width: 'auto',
    margin: theme.spacing(1),
    '&:hover': {
      filter: 'brightness(75%)'
    }
  },
  icono: {
    fill: 'white',
    position: 'absolute',
    top: '0px',
    left: '0px',
    width: '2.6rem',
    height: '100%'
  },
  linea: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly'
  },
  hr: {
    width: '40%'
  },
  aire2: {
    marginTop: theme.spacing(2)
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='down' ref={ref} {...props} />;
});

const IngresoMain = ({ titulo }) => {
  const [isSubmitionCompleted, setSubmitionCompleted] = useState(false);
  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword1: false
  });
  const abiertoDialogo = useSelector((state) => state.dialogo.abiertoDialogo);
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleDatos = (valores) => {
    const valoresFinales = { ...values, ...valores };
    dispatch(_cmdIngresarUsuario(valoresFinales));
  };

  const handleClickShowPassword1 = () => {
    setValues({ ...values, showPassword1: !values.showPassword1 });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onClickRegistro = () => {
    dispatch(_cmdresetDialogo());
    setTimeout(() => {
      dispatch(_cmdmostrarDialogo('registro'));
    }, 500);
  };

  return (
    <Dialog
      aria-labelledby='form-dialog-title'
      fullScreen={fullScreen}
      maxWidth='xs'
      onClose={() => dispatch(_cmdresetDialogo())}
      open={abiertoDialogo}
      TransitionComponent={Transition}
    >
      <DialogTitle id='form-dialog-title'>{titulo}</DialogTitle>
      <DialogContent dividers={true}>
        {!isSubmitionCompleted && (
          <Box m={1} textAlign='center'>
            <button
              className={classNames(classes.boton, classes.google)}
              onClick={() => dispatch(_cmdIngresarGoogle())}
              type='button'
            >
              <svg
                aria-labelledby='google'
                className={classes.icono}
                role='img'
                viewBox='0, 0, 100, 100'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M74.37 50.541c0-1.69-.152-3.314-.434-4.876H51.477v9.224h12.834a10.967 10.967 0 0 1-4.759 7.197v5.985h7.707c4.509-4.154 7.111-10.266 7.111-17.527z' />
                <path d='M51.477 73.847c6.439 0 11.838-2.134 15.782-5.778l-7.707-5.983c-2.135 1.431-4.865 2.277-8.075 2.277-6.212 0-11.471-4.197-13.343-9.832h-7.967v6.176a23.837 23.837 0 0 0 21.31 13.14z' />
                <path d='M38.134 54.531A14.352 14.352 0 0 1 37.386 50c0-1.574.272-3.1.748-4.531v-6.176h-7.967A23.837 23.837 0 0 0 27.63 50c0 3.849.92 7.488 2.537 10.707l7.965-6.176z' />
                <path d='M51.477 35.637c3.501 0 6.644 1.204 9.117 3.567l6.839-6.839c-4.13-3.851-9.529-6.212-15.956-6.212-9.324 0-17.385 5.342-21.31 13.14l7.965 6.176c1.879-5.637 7.135-9.832 13.345-9.832z' />
              </svg>
              Continuar con Google
            </button>
            <button
              className={classNames(classes.boton, classes.fb)}
              type='button'
            >
              <svg
                aria-labelledby='fb'
                className={classes.icono}
                role='img'
                viewBox='0, 0, 100, 100'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M55 75.35H44.249V49.997h-5.373v-8.736h5.373v-5.245c0-7.126 3.032-11.366 11.651-11.366h7.175v8.739h-4.484c-3.356 0-3.578 1.221-3.578 3.499l-.014 4.373h8.125l-.951 8.736h-7.174V75.35H55z' />
              </svg>
              Continuar con Facebook
            </button>
            <button
              className={classNames(classes.boton, classes.apple)}
              type='button'
            >
              <svg
                aria-labelledby='apple'
                className={classes.icono}
                role='img'
                viewBox='0, 0, 100, 100'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M72.309 62.148a30.036 30.036 0 01-2.973 5.345c-1.563 2.228-2.842 3.771-3.829 4.627-1.529 1.406-3.167 2.126-4.921 2.167-1.259 0-2.778-.358-4.545-1.085-1.774-.723-3.404-1.082-4.894-1.082-1.563 0-3.24.359-5.033 1.082-1.795.727-3.242 1.106-4.348 1.143-1.682.072-3.359-.669-5.033-2.225-1.068-.932-2.404-2.529-4.004-4.791-1.718-2.416-3.13-5.218-4.236-8.413-1.184-3.45-1.778-6.791-1.778-10.025 0-3.706.801-6.901 2.404-9.579 1.261-2.151 2.937-3.848 5.036-5.094a13.555 13.555 0 016.807-1.921c1.337 0 3.089.413 5.266 1.226 2.171.815 3.565 1.228 4.176 1.228.457 0 2.006-.483 4.631-1.447 2.483-.893 4.579-1.263 6.295-1.117 4.652.375 8.146 2.209 10.47 5.512-4.16 2.521-6.218 6.051-6.177 10.58.038 3.528 1.318 6.464 3.833 8.795a12.616 12.616 0 003.829 2.511 41.013 41.013 0 01-.976 2.563zm-10.668-41.98c0 2.765-1.01 5.347-3.024 7.737-2.43 2.84-5.369 4.482-8.556 4.223 0-2.654 1.156-5.495 3.208-7.818 1.024-1.176 2.327-2.154 3.907-2.934 1.577-.768 3.068-1.193 4.471-1.266.041.37.058.739.058 1.106z' />
              </svg>
              Continuar con Apple
            </button>
            <div className={classes.linea}>
              <Divider className={classes.hr} />
              <Typography color='inherit' variant='subtitle2'>
                ó
              </Typography>
              <Divider className={classes.hr} />
            </div>
            <Typography color='inherit' variant='subtitle1'>
              Ingresa tu correo
            </Typography>
            <Formik
              initialValues={{ email: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  setValues({ ...values, email: values.email });
                  setSubmitionCompleted(true);
                  setSubmitting(false);
                }, 500);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email('Por favor ingrese un correo válido.')
                  .required('Correo requerido.')
              })}
            >
              {({ submitForm, isSubmitting, isValid }) => (
                <Form autoComplete='on' noValidate>
                  {isSubmitting && <Cargador />}
                  <Field
                    className={classes.aire}
                    component={TextField}
                    disabled={isSubmitting}
                    id='outlined-basic'
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          {isValid ? (
                            <VerifiedUserIcon style={{ color: 'green' }} />
                          ) : (
                            <VerifiedUserIcon />
                          )}
                        </InputAdornment>
                      ),
                      color: 'secondary'
                    }}
                    label='Correo Electrónico'
                    name='email'
                    type='email'
                    variant='outlined'
                  />
                  <Button
                    className={classes.aire}
                    color='secondary'
                    disabled={isValid ? false : true}
                    fullWidth
                    onClick={submitForm}
                    variant='contained'
                  >
                    Continuar
                  </Button>
                  <Divider className={classes.aire} />
                  <DialogContentText style={{ fontSize: '16px' }}>
                    ¿No tienes una cuenta?{' '}
                    <Link color='primary' onClick={onClickRegistro}>
                      Regístrate
                    </Link>
                  </DialogContentText>
                </Form>
              )}
            </Formik>
          </Box>
        )}
        {isSubmitionCompleted && (
          <Box m={1} textAlign='center'>
            <DialogContentText component='div'>
              A continuación:
              <Typography color='secondary' variant='subtitle2'>
                {values.email}
              </Typography>
            </DialogContentText>
            <Formik
              initialValues={{ password: '' }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  handleDatos(values);
                }, 500);
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string()
                  .required('La contraseña es requerida.')
                  .min(5, 'Mínimo 5 caracteres.')
              })}
            >
              {({ submitForm, isSubmitting, errors }) => (
                <Form autoComplete='off' noValidate>
                  <>
                    <Field
                      autoFocus
                      className={classes.aire}
                      component={TextField}
                      disabled={isSubmitting}
                      id='password'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='Ver'
                              onClick={handleClickShowPassword1}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {values.showPassword1 ? (
                                <VisibilityIcon />
                              ) : (
                                <VisibilityOffIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                        color: 'secondary'
                      }}
                      label='Ingrese su contraseña'
                      name='password'
                      type={values.showPassword1 ? 'text' : 'password'}
                      variant='outlined'
                    />
                  </>
                  <Button
                    className={classes.aire}
                    color='secondary'
                    disabled={isSubmitting}
                    fullWidth
                    onClick={submitForm}
                    variant='contained'
                  >
                    Aceptar
                  </Button>
                  <DialogContentText style={{ fontSize: '16px' }}>
                    ¿No tienes una cuenta?{' '}
                    <Link color='secondary' onClick={onClickRegistro}>
                      Regístrate
                    </Link>
                    .
                  </DialogContentText>
                </Form>
              )}
            </Formik>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

IngresoMain.propTypes = {
  titulo: PropTypes.string
};

export default IngresoMain;
